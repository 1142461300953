import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DateRangeFilterContainer,
  DateRangeTitle,
  DatePickerWrapper,
  DatePickerLabel,
  StyledDatePicker,
  FilterLabel,
  DatePickersContainer
} from './Analytics.style';

interface DateRangeFilterProps {
    onChange: (fromDate: Date | null, toDate: Date | null) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ onChange }) => {
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);

    const handleFromDateChange = (date: Date | null) => {
        setFromDate(date);
        onChange(date, toDate);
    };

    const handleToDateChange = (date: Date | null) => {
        setToDate(date);
        onChange(fromDate, date);
    };

    return (
        <DateRangeFilterContainer>
            <FilterLabel>Filter by Date:</FilterLabel>
            <DatePickersContainer>
            <DatePickerWrapper>
                <DatePickerLabel>From:</DatePickerLabel>
                <StyledDatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    selectsStart
                    startDate={fromDate ?? undefined}
                    endDate={toDate ?? undefined}
                />
            </DatePickerWrapper>
            <DatePickerWrapper>
                <DatePickerLabel>To:</DatePickerLabel>
                <StyledDatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    selectsEnd
                    startDate={fromDate ?? undefined}
                    endDate={toDate ?? undefined}
                    minDate={fromDate ?? new Date(2020, 0, 1)}
                />
            </DatePickerWrapper>
            </DatePickersContainer>
        </DateRangeFilterContainer>
    );
};

export default DateRangeFilter;
