import React, { FC, useState } from 'react';
import {
  AddRegionContainer,
  InfoLabel,
  Label,
  MapInputContainer,
  MapInputWithLabel,
  RegionInput,
} from './Map.style';
import { CustomButton } from '../../../components/utils/table.styled';
import colors from '../../../utils/colors';
import { useAppDispatch } from '../../../redux/hooks';
import { changeNewRegion, removeActiveMap } from '../../../redux/map/mapSlice';
import { Map } from '../../../models/mapElement/map';
import { hideModal } from '../../../redux/modal/modalSlice';
import { createNewMap, updateExistingMap } from '../../../redux/map/mapThunk';
import { selectActivePremise } from '../../../redux/premise/premiseSelector';
import { selectMap } from '../../../redux/map/mapSelectors';

const AddMap: FC = () => {
  const [error, setError] = useState('');
  const premise = selectActivePremise();
  const activeMap = selectMap();
  const [name, setName] = useState(activeMap.name || '');
  const dispatch = useAppDispatch();

  const handleNameChange = (event: any) => {
    setName(event.target.value.toString());
    setError('');
  };

  const saveMap = () => {
    if (name === '') {
      setError('Name cannot be empty');
      return;
    }

    if (activeMap.id === '') {
      createMap();
    } else {
      updateMap();
    }
  };

  const updateMap = () => {
    if (!premise) {
      return;
    }
    const mapToUpdate: Map = {
      id: activeMap.id,
      premiseId: premise?.id,
      regions: activeMap.regionList,
      name,
    };

    dispatch(updateExistingMap(mapToUpdate))
      .unwrap()
      .then(data => {
        dispatch(hideModal());
        dispatch(removeActiveMap());
      })
      .catch(err => console.log(err));
  };

  const createMap = () => {
    if (!premise) {
      return;
    }
    const newMap: Map = {
      id: '',
      premiseId: premise?.id,
      name,
      regions: [],
    };
    dispatch(createNewMap(newMap))
      .unwrap()
      .then(() => {
        dispatch(changeNewRegion(true));
        dispatch(hideModal());
      })
      .catch(err => console.log(err));
  };

  return (
    <AddRegionContainer>
      <MapInputContainer>
        <MapInputWithLabel>
          <Label>Map Name: </Label>
          <RegionInput
            type='string'
            placeholder={activeMap.name || ''}
            value={name}
            onChange={handleNameChange}
          />
        </MapInputWithLabel>
      </MapInputContainer>
      {error && <InfoLabel style={{ color: 'red' }}>{error}</InfoLabel>}
      <CustomButton color={colors.confirm} onClick={saveMap}>
        Save
      </CustomButton>
    </AddRegionContainer>
  );
};

export default AddMap;
