import styled from 'styled-components';
import colors from '../colors';
import { Column, Row } from '../layout';
import { Label } from '../../views/host-admin/map/Map.style';

export const ElementPickContainer = styled(Column)`
  height: 100%;
  padding-inline: 0.5rem;
  align-items: space-between;
  justify-content: space-between;
  padding-block: 3px;
`;
export const ElementBlock = styled(Column)`
  justify-content: space-between;
  align-items: center;
  height: max-content;
`;

export const TempTableInfoContainer = styled(Row)`
  width: 100%;
  display: block;
  align-items: space-between;
  justify-content: space-between;
`;

export const TempTableLabel = styled.span`
  margin: 0px;
  font-size: 12px;
`;

export const RemoveTempTableButton = styled.button`
  width: auto;
  border-radius: 5px;
  border: 1px solid ${colors.danger};
  background-color: transparent;
  color: ${colors.danger};
  font-size: 10px;
  :hover {
    background-color: ${colors.danger};
    color: white;
  }
`;
