import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { Column } from '../layout';
import { Label } from '../../views/host-admin/map/Map.style';

export const ConfirmationDialogContainer = styled(Column)`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`;

export const ConfirmationTitle = styled(Label)``;
export const ConfirmationDescription = styled(Label)``;
export const ConfirmationButtons = styled.div`
  width: 15rem;
  justify-content: space-between;
`;
