import React, { FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PremiseTime from '../../../../components/premiseTime/premise.time.component';
import { PredefinedFlter } from '../../../../models/predefinedFilter/predefinedFilter';
import { Image } from '../../../../models/premise/image';
import { Location } from '../../../../models/premise/location';
import { Premise, TableType } from '../../../../models/premise/premise';
import { PremiseTimeSettings } from '../../../../models/premise/premiseTimeSettings';
import { PremiseType } from '../../../../models/premiseType/premiseType';
import { Role } from '../../../../models/user/role';
import { User } from '../../../../models/user/user';
import { useAppDispatch } from '../../../../redux/hooks';
import { selectActivePremise } from '../../../../redux/premise/premiseSelector';
import { createPremise, updatePremise } from '../../../../redux/premise/premiseThunk';
import { createManager } from '../../../../redux/user/userThunk';
import {
  getAllPredefinedFiltersApi,
  getAllPremiseTypesApi,
  getAllTagsApi,
} from '../../../../services/api/premiseApi';
import { getManagerApi, getUsersByRole } from '../../../../services/api/userApi';
import { notNullOrEmpty } from '../../../../utils/converters/notNullOrEmpty';
import { ContainerDnd } from '../../../../utils/dnd/container';
import { DropdownModel } from '../../../../utils/dropdown/dropdown.model';
import { newObjectId } from '../../../../utils/helpers';
import { Map } from '../../../../utils/maps/map';
import { Pagination, parsePagination } from '../../../../utils/pagination/pagination';
import { Error, RoundButton, Separator } from './AddPremise.styled';
import { compressImage } from '../../../../utils/image-compressor/image-compressor';

type Props = {
  show: boolean;
  onHide: () => void;
  edit: boolean;
};

const AddPremise: FC<Props> = ({ show, onHide, edit }) => {
  const premiseModel = selectActivePremise();
  const dispatch = useAppDispatch();

  const [validated, setValidated] = useState(false);
  const [premise, setPremise] = useState<Premise>(premiseModel);
  const [numberOfPhoneNumbers, setNumberOfPhoneNumbers] = useState<number>(1);
  const [managers, setManagers] = useState<User[]>([]);
  const [menuItems, setMenuItems] = useState<Image[]>(premiseModel?.menuItems ?? []);
  const [images, setImages] = useState<Image[]>(premiseModel?.images ?? []);
  const [pagination, setPagination] = React.useState<Pagination>({
    itemsPerPage: 5,
    totalPages: 0,
    currentPage: 1,
  });
  const [requestedPage, setRequestedPage] = useState<number>(1);
  const [premiseTypes, setPremiseTypes] = useState<PremiseType[]>([]);
  const [predefinedFilters, setPredefinedFilters] = useState<PredefinedFlter[]>([]);
  const [selectedPredefinedFilters, setSelectedPredefinedFilters] = useState<PredefinedFlter[]>(
    predefinedFilters.filter(x => premiseModel?.predefinedFilters?.includes(x.name)) || [],
  );
  const [existingTags, setExistingTags] = useState<DropdownModel[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(premiseModel?.tags || []);
  const [user, setUser] = useState<User>({} as User);
  const [tableTypes, setTableTypes] = useState<TableType[]>(premiseModel?.tableTypes ?? []);
  const [newTableType, setNewTableType] = useState<string>('');
  const [location, setLocation] = useState<Location>(premiseModel?.location);

  const [premiseType, setPremiseType] = useState<PremiseType>();
  const [managerEmail, setManagerEmail] = useState<string>('');
  const pageSize = 5;

  useEffect(() => {
    setPremise(premiseModel);
    setMenuItems(premiseModel?.menuItems ?? []);
    setImages(premiseModel?.images ?? []);
    setSelectedTags(premiseModel?.tags ?? []);
    setLocation(premiseModel?.location);
    setNumberOfPhoneNumbers(premiseModel?.phoneNumbers?.length ?? 1);
    setSelectedPredefinedFilters(
      predefinedFilters.filter(x => premiseModel?.predefinedFilters?.includes(x.name)) || [],
    );
    const selectedPremiseType =
      premiseTypes.find(type => type.name === premiseModel?.premiseTypeName) ?? ({} as PremiseType);
    setPremiseType(selectedPremiseType);

    setTableTypes(premiseModel?.tableTypes ?? []);
    if (edit && premiseModel?.managerId) {
      getManagerApi(premiseModel?.managerId)
        .then(result => {
          setUser({
            id: result?.data?.id,
            name: result?.data?.name,
            surname: result?.data?.surname,
            profileImage: result?.data?.profileImage,
            address: result?.data?.address,
            phoneNumber: result?.data?.phoneNumber,
            identity: { email: result?.data?.email, role: result?.data?.role },
          });
          setManagerEmail(result?.data?.email);
        })
        .catch(error => setUser({} as User));
    } else {
      setUser({} as User);
    }
  }, [premiseModel, edit]);

  useEffect(() => {
    getUsersByRole(Role.Manager.toString(), { itemsPerPage: pageSize, currentPage: requestedPage })
      .then(result => {
        setManagers(result.data);
        setPagination(parsePagination(result.headers['x-pagination']));
      })
      .catch(error => console.log(error));
  }, [requestedPage]);

  useEffect(() => {
    getAllPremiseTypesApi()
      .then(result => setPremiseTypes(result.data))
      .catch(error => console.log(error));

    getAllTagsApi()
      .then(result => {
        setExistingTags(
          result.data.map(x => {
            const obj: DropdownModel = { name: x.name, value: x.name };
            return obj;
          }),
        );
      })
      .catch(error => console.log(error));

    getAllPredefinedFiltersApi()
      .then(result => setPredefinedFilters(result.data))
      .catch(error => console.log(error));
  }, []);

  const handlePremiseType = (value: any) => {
    setPremiseType(value);
    setPremise({ ...premise, premiseTypeId: value.id });
  };

  const handlePredefinedFilters = (value: any) => {
    const filters = value as PredefinedFlter[];
    setSelectedPredefinedFilters(filters);
  };
  const handlePremiseTag = (value: any) => {
    if (!value) {
      // If value is empty, clear selectedTags
      setSelectedTags([]);
      return;
    }

    const tags = (value as any[]).map(x => x.value);
    const newSelectedTags = [...selectedTags];

    // Add new tags
    tags?.forEach(element => {
      if (!newSelectedTags?.includes(element)) {
        newSelectedTags?.push(element);
      }
    });

    // Remove tags that are no longer selected
    const updatedSelectedTags = newSelectedTags?.filter(tag => tags?.includes(tag));

    setSelectedTags(updatedSelectedTags);
  };

  const handlePremiseTimeSettiings = (timeSettings: PremiseTimeSettings) => {
    setPremise({ ...premise, timeSettings });
  };

  const changePagination = (page: number) => {
    setRequestedPage(page);
  };

  const handlePhoneNumbers = (phoneNumber: string, index: number) => {
    const updatedPhoneNumbers = premise.phoneNumbers ? [...premise.phoneNumbers] : [];
    updatedPhoneNumbers[index] = phoneNumber;
    setPremise({ ...premise, phoneNumbers: updatedPhoneNumbers });
  };

  const onAddMenuItem = (event: any) => {
    const files = (event?.target as HTMLInputElement)?.files;
    if (files) {
      const tempMenuItems: Image[] = [];
      for (let i = 0; i < files.length; i += 1) {
        const orderOfImage = (menuItems ? menuItems.length : 0) + tempMenuItems.length + 1;
        const image: Image = { imageFile: files[i], order: orderOfImage, path: '' };
        tempMenuItems.push(image);
      }
      const updatedImages = [...menuItems, ...tempMenuItems];
      setMenuItems(updatedImages);
    }
  };



  const onAddImage = async (event: any) => {
    const files = (event?.target as HTMLInputElement)?.files;
    if (files) {
      const compressedImagesPromises = Array.from(files).map((file) => compressImage(file));

      try {
        // Wait for all images to be compressed
        const compressedImages = await Promise.all(compressedImagesPromises);

        // Create Image objects from the compressed images
        const tempImages: Image[] = compressedImages.map((compressedImage, index) => {
          const orderOfImage = (images ? images.length : 0) + index + 1;
          return { imageFile: compressedImage, order: orderOfImage, path: '' };
        });

        // Update state with the new images
        const updatedImages = [...(images || []), ...tempImages];
        setImages(updatedImages);
      } catch (error) {
        console.error('Error compressing images:', error);
      }
    }
  };

  const adjustOrders = (updatedCards: Image[]) => {
    const cardsWithMinusOne = updatedCards.filter(card => card.order === -1);

    const otherCards = updatedCards
      .filter(card => card.order !== -1)
      .sort((a, b) => a.order - b.order);

    // Ažuriramo order za ostale slike
    const adjustedOtherCards = otherCards.map((card, index) => {
      return { ...card, order: index + 1 };
    });

    // Kombinujemo slike sa order -1 i ostale slike
    return [...cardsWithMinusOne, ...adjustedOtherCards];
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const filterInvalidImages = (images: Image[]): Image[] => {
      const temp = images.filter(image => !(image.order === -1 && image.path === ''));
      const temp1 = adjustOrders(temp);
      return temp1;
    };

    try {
      const submitModel = {
        ...premise,
        menuItems: filterInvalidImages(menuItems),
        images: filterInvalidImages(images),
        tags: selectedTags,
        predefinedFilters: selectedPredefinedFilters.map(x => x.id),
        tableTypes,
        premiseTypeId: premiseType?.id ?? '',
      };
      if (managerEmail !== user.identity.email) {
        const managerData = await dispatch(
          createManager({
            identity: {
              email: user.identity.email,
              password: '1234',
            },
            phoneNumber: user.phoneNumber,
          } as unknown as User),
        );
        submitModel.managerId = managerData.payload;
      }

      if (edit) {
        // If editing, only dispatch update action, don't create a new manager
        await dispatch(updatePremise(submitModel));
      } else {
        await dispatch(createPremise(submitModel));
      }
      onHide();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const handleLocation = (location: Location) => {
    setPremise({ ...premise, location });
  };

  const handleMenuImageReorder = (menuItems: any[]) => {
    const updatedMenuImages = menuItems.map((element, index) => ({
      ...element,
      order: element?.deleted ? -1 : index + 1,
    }));
    setMenuItems(updatedMenuImages);
  };

  const handleImageReorder = (images: any[]) => {
    const updatedImages = images.map((element, index) => ({
      ...element,
      order: element?.deleted ? -1 : index + 1,
    }));
    setImages(updatedImages);
  };

  const addTableType = () => {
    if (
      newTableType.trim() !== '' &&
      !tableTypes?.some(type => type.name === newTableType.trim())
    ) {
      setTableTypes(prevTypes => [...prevTypes, { id: newObjectId(), name: newTableType.trim() }]);
      setNewTableType('');
    }
  };

  const deleteTableType = (typeId: string) => {
    setTableTypes(prevTypes => prevTypes.filter(type => type.id !== typeId));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {edit ? 'Edit premise' : 'Add premise'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit} id='premise'>
          <Form.Group className='mb-1' controlId='validation1'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter name'
              value={premise?.name}
              onChange={e => setPremise({ ...premise, name: e.target.value })}
              required
            />
            <Form.Control.Feedback type='invalid'>Name is incorrect!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-1' controlId='validation2'>
            <Form.Label>PIB (Personal Identification Number)</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter PIB'
              value={premise?.pib}
              onChange={e => setPremise({ ...premise, pib: e.target.value })}
              required
            />
            <Form.Control.Feedback type='invalid'>PIB is incorrect!</Form.Control.Feedback>
          </Form.Group>

          <Separator />

          <Form.Group className='mb-1' controlId='formDescription'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type='text'
              placeholder='Description'
              required
              value={premise?.description}
              onChange={e => setPremise({ ...premise, description: e.target.value })}
            />
            <Form.Control.Feedback type='invalid'>Description is incorrect!</Form.Control.Feedback>
          </Form.Group>

          <Separator />

          {Array.from({ length: numberOfPhoneNumbers }).map((_, index) => (
            <Form.Group key={index + 100} controlId='phoneNumber'>
              <Form.Label>Phone number {index + 1}</Form.Label>
              <Form.Control
                type='text'
                required
                placeholder='PhoneNumber'
                onChange={e => handlePhoneNumbers(e.target.value, index)}
                value={premise?.phoneNumbers ? premise?.phoneNumbers[index] : undefined}
              />
              <Form.Control.Feedback type='invalid'>
                Phone number is incorrect!
              </Form.Control.Feedback>
            </Form.Group>
          ))}
          <RoundButton onClick={() => setNumberOfPhoneNumbers(prev => prev + 1)}>&#43;</RoundButton>
          <RoundButton
            onClick={() => {
              setNumberOfPhoneNumbers(prev => (prev > 1 ? prev - 1 : prev));
              const updatedPhoneNumbers = premise?.phoneNumbers?.slice(0, numberOfPhoneNumbers - 1);
              setPremise({ ...premise, phoneNumbers: updatedPhoneNumbers });
            }}
          >
            &#45;
          </RoundButton>

          <Separator />

          <Form.Group className='mb-1' controlId='formEmail'>
            <Form.Label>Manager Email</Form.Label>
            <Form.Control
              type='text'
              placeholder='Email'
              required
              value={user?.identity?.email}
              onChange={e =>
                setUser({ ...user, identity: { ...user.identity, email: e.target.value } })
              }
            />
            <Form.Control.Feedback type='invalid'>Email is incorrect!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-1' controlId='formPhoneNumber'>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type='text'
              placeholder='PhoneNumber'
              required
              value={user?.phoneNumber}
              onChange={e => setUser({ ...user, phoneNumber: e.target.value })}
            />
            <Form.Control.Feedback type='invalid'>Phone Number is incorrect!</Form.Control.Feedback>
          </Form.Group>

          <Separator />

          <Form.Group className='mb-1' controlId='formImage'>
            <Row>
              <Row>
                <Form.Label>Chose premise images</Form.Label>
              </Row>
              <Row>
                {
                  <input
                    style={{ marginLeft: 'calc(var(--bs-gutter-x) * .5)', width: '240px' }}
                    className='btn btn-primary'
                    type='file'
                    onChange={onAddImage}
                    multiple
                  />
                }
              </Row>
            </Row>
          </Form.Group>
          <DndProvider backend={HTML5Backend}>
            <ContainerDnd menuItems={images} handleImageReorder={handleImageReorder} />
          </DndProvider>

          <Form.Group className='mb-1' controlId='formImage'>
            <Row>
              <Row>
                <Form.Label>Chose menu items</Form.Label>
              </Row>
              <Row>
                {
                  <input
                    style={{ marginLeft: 'calc(var(--bs-gutter-x) * .5)', width: '240px' }}
                    className='btn btn-primary'
                    type='file'
                    onChange={onAddMenuItem}
                    multiple
                  />
                }
              </Row>
            </Row>
          </Form.Group>
          <DndProvider backend={HTML5Backend}>
            <ContainerDnd menuItems={menuItems} handleImageReorder={handleMenuImageReorder} />
          </DndProvider>

          <Separator />

          <Row style={{ width: '100%', height: '400px', marginTop: '20px' }}>
            <Error hidden={notNullOrEmpty(premise?.location)}>Location must be selected!</Error>
            <Map handleLocation={handleLocation} location={premiseModel?.location} />
          </Row>

          <Separator />

          <PremiseTime
            handlePremiseTime={handlePremiseTimeSettiings}
            timeSettings={premise?.timeSettings}
          />

          <Separator />
          <Form.Label>{`Select premise type`}</Form.Label>
          <Select
            onChange={e => handlePremiseType(e)}
            options={premiseTypes}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={premiseType}
          />
          <Error hidden={notNullOrEmpty(premiseType)}>Premise type must be selected!</Error>
          <Separator />

          <Form.Label>{`Select predefined filters`}</Form.Label>
          <CreatableSelect
            isMulti
            onChange={e => handlePredefinedFilters(e)}
            options={predefinedFilters}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={selectedPredefinedFilters}
          />

          <Form.Label>{`Select premise tags or add new`}</Form.Label>
          <CreatableSelect
            isMulti
            onChange={e => handlePremiseTag(e)}
            options={existingTags}
            getOptionLabel={option => option.value}
            getOptionValue={option => option.value}
            value={selectedTags?.map(tag => ({ value: tag, name: tag }))} // Adjusted value
          />

          <Form.Group className='mb-1' controlId='formTableTypes'>
            <Form.Label>Table Types</Form.Label>
            {tableTypes?.map((type, index) => (
              <Row key={type.id}>
                <Form.Control
                  type='text'
                  value={type.name}
                  onChange={e => {
                    const updatedTypes = [...tableTypes];
                    updatedTypes[index].name = e.target.value;
                    setTableTypes(updatedTypes);
                  }}
                />
                <Button variant='danger' onClick={() => deleteTableType(type.id)}>
                  X
                </Button>
              </Row>
            ))}
            <Row>
              <Form.Control
                type='text'
                placeholder='Enter table type'
                value={newTableType}
                onChange={e => setNewTableType(e.target.value)}
              />
              <Button onClick={addTableType}>Add</Button>
            </Row>
          </Form.Group>
          <Separator />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' type='submit' form='premise'>
          {edit ? 'Edit' : 'Add'}
        </Button>
        {/* <Button variant='primary' onClick={testit} >Test</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default AddPremise;
