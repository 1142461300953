import React from 'react';
import { Select } from 'antd';
import { Status } from '../../models/reservation/status';
import { FilterContainer, FilterLabel, StyledSelect } from './Analytics.style';

const { Option } = Select;

interface StatusFilterProps {
  onChange: (selectedStatuses: number[]) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ onChange }) => {
  const handleChange = (selectedValues: number[]) => {
    onChange(selectedValues);
  };

  const getStatusName = (status: Status): string => {
    return Status[status].charAt(0).toUpperCase() + Status[status].slice(1).toLowerCase();
  };

  return (
    <FilterContainer>
      <FilterLabel>Filter by Status:</FilterLabel>
      <StyledSelect
        mode="multiple"
        placeholder="Select statuses"
        onChange={(value: unknown) => handleChange(value as number[])}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {Object.values(Status)
          .filter((value) => typeof value === 'number')
          .map((status) => (
            <Option key={status} value={status}>
              {getStatusName(status as Status)}
            </Option>
          ))}
      </StyledSelect>
    </FilterContainer>
  );
};

export default StatusFilter;