import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { getAllPremisesApi } from '../../services/api/premiseApi';
import { Premise } from '../../models/premise/premise';
import { FilterContainer, FilterLabel, StyledSelect } from './Analytics.style';

const { Option } = Select;

interface PremiseFilterProps {
  onChange: (selectedPremises: string[]) => void;
}

const PremiseFilter: React.FC<PremiseFilterProps> = ({ onChange }) => {
  const [premises, setPremises] = useState<Premise[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPremises();
  }, []);

  const fetchPremises = async () => {
    setLoading(true);
    try {
      const response = await getAllPremisesApi({
        itemsPerPage: 1,
        currentPage: 1, // This value doesn't matter when WithoutPagination is true
        withoutPagination: true
      });
      setPremises(response.data);
    } catch (error) {
      console.error('Error fetching premises:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (selectedValues: string[]) => {
    onChange(selectedValues);
  };

  return (
    <FilterContainer>
      <FilterLabel>Filter by Premise:</FilterLabel>
      <StyledSelect
        mode="multiple"
        placeholder="Select premises"
        onChange={(value: unknown) => handleChange(value as string[])}
        loading={loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {premises.map((premise) => (
          <Option key={premise.id} value={premise.id}>
            {premise.name}
          </Option>
        ))}
      </StyledSelect>
    </FilterContainer>
  );
};

export default PremiseFilter;