import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalSlice {
  show: boolean;
  content: any;
}

const initialState: ModalSlice = {
  show: false,
  content: null,
};

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    hideModal: state => {
      state.show = false;
      state.content = null;
    },
    showModal: (state, action: PayloadAction<any>) => {
      state.show = true;
      state.content = action.payload;
    },
  },
});

export const { hideModal, showModal } = modalSlice.actions;

export default modalSlice.reducer;
