import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { PremiseAnalyticsResponseModel } from '../../models/analytics/premiseAnalytics';

interface PremiseAnalyticsTableProps {
  data: PremiseAnalyticsResponseModel[];
}

const PremiseAnalyticsTable: React.FC<PremiseAnalyticsTableProps> = ({ data }) => {

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Premise Name</TableCell>
            <TableCell align="right">Reservation Count</TableCell>
            <TableCell align="right">Total Amount Spent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.premiseId}>
              <TableCell component="th" scope="row">
                <Link to={`/hostAdmin/reservation-analytics/${row.premiseId}`}>
                  {row.premiseName || 'N/A'}
                </Link>
              </TableCell>
              <TableCell align="right">{row.totalReservations ?? 'N/A'}</TableCell>
              <TableCell align="right">
                {row.totalAmountSpent != null ? row.totalAmountSpent.toFixed(2) : 'N/A'} RSD
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PremiseAnalyticsTable;
