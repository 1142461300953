import styled from 'styled-components';

export const AuthPageContainer = styled.div`
  display: flex;
  border: 2px solid #d3d3d3;
  border-radius: 0.5rem;
  margin: 10rem auto 1rem auto;
  padding: 1rem;
  text-align: left;
  width: 40rem;
`;
