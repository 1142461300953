import styled from 'styled-components';
import { Column } from '../../../utils/layout';

export const DashboardPageContainer = styled(Column)`
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled(Column)`
  width: 20%;
  align-self: center;
`;

export const AnalyticsSection = styled.div`
  margin-top: 20px;
`;

export const AnalyticsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const AnalyticsContent = styled.div`
  display: flex;
  gap: 20px;
`;

export const AnalyticsTableWrapper = styled.div`
  flex: 1;
`;

export const AnalyticsFilters = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DatePickerWrapper = styled.div`
max-width: 250px;
margin: 15 auto;
`;