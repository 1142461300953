import React, { FC, memo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { AuthPageContainer } from './Auth.style';
import { useAppDispatch } from '../../redux/hooks';
import { loginUser } from '../../redux/user/userThunk';
import { selectUser } from '../../redux/user/userSelectors';
import { BASE_ROUTE, DASHBOARD } from '../../services/routes';
import { LoginModel } from '../../models/user/loginModel';

const { Group, Label, Control } = { ...Form };

const Auth: FC = () => {
  const dispatch = useAppDispatch();
  const user = selectUser();
  const [errorVal, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>({
    mode: 'onBlur',
  });

  const SubmitHandler = (formData: LoginModel) => {
    dispatch(loginUser({ email: formData.email, password: formData.password }))
      .unwrap()
      .then(() => {
        navigate(`${BASE_ROUTE}/${DASHBOARD}`);
      })
      .catch(() => {
        setError(' Invalid Username or Password ');
      });
  };

  const registerOptions = {
    email: {
      required: {
        value: true,
        message: 'Email is required',
      },
      pattern: {
        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        message: 'Format not valid',
      },
    },
    password: { required: 'Password is required' },
  };

  const token = !localStorage.getItem('token');

  return token ? (
    <AuthPageContainer>
      <Container>
        <h3 className='text-center'>MyHost admin portal</h3>
        {errorVal && (
          <div className='mb-3'>
            <Alert variant='danger'>
              <FaInfoCircle /> {errorVal}
            </Alert>
          </div>
        )}
        <form onSubmit={handleSubmit(SubmitHandler)}>
          <div className='group'>
            <Label>Email</Label>
            <Control
              type='text'
              placeholder='Email'
              {...register('email', registerOptions.email)}
            />
            {errors?.email && <span className='text-danger'> {errors?.email.message} </span>}
          </div>
          <div className='group' style={{ position: 'relative' }}>
            <Label>Password</Label>
            <Control
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              {...register('password', registerOptions.password)}
            />
            {errors?.password && <span className='text-danger'> {errors?.password.message} </span>}
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{ position: 'absolute', right: '10px', top: '35px', cursor: 'pointer' }}
            >
              <Label onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'hide' : 'show'}
              </Label>
            </span>
          </div>
          <div className='group text-center'>
            <Button variant='primary' type='submit' className='m-3'>
              {user.loading === 'pending' ? <Spinner animation={'border'} /> : 'Sign in'}
            </Button>
          </div>
        </form>
      </Container>
    </AuthPageContainer>
  ) : (
    <Navigate to={`${BASE_ROUTE}/${DASHBOARD}`} replace />
  );
};

export default memo(Auth);
