import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Map } from '../../models/mapElement/map';
import { Premise } from '../../models/premise/premise';
import { changeMainTableMap, getAllPremises, getPremiseMaps } from './premiseThunk';

// TODO: add next methods to slice
// getAllPremiseTypesApi
// getAllTagsApi
// getAllPredefinedFiltersApi

interface PremiseSlice {
  activePremise: Premise;
  premisesList: Premise[];
  mapList: Map[];
}

const initialState: PremiseSlice = {
  activePremise: {} as Premise,
  premisesList: [],
  mapList: [],
};

const premiseSlice = createSlice({
  name: 'premisesList',
  initialState,
  reducers: {
    clearList: state => {
      state.activePremise = {} as Premise;
      state.premisesList = [];
    },
    setActivePremise: (state, action: PayloadAction<Premise>) => {
      state.activePremise = action.payload;
    },
    removeActivePremise: state => {
      state.activePremise = {} as Premise;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllPremises.fulfilled, (state, action) => {
        const premiseList = action.payload.data as Premise[];
        console.log(premiseList);

        state.premisesList = state.premisesList.concat(premiseList);
      })
      .addCase(getPremiseMaps.fulfilled, (state, action) => {
        const premiseMaps = action.payload.data as Map[];
        console.log(premiseMaps);

        state.mapList = premiseMaps;
      })
      .addCase(changeMainTableMap.fulfilled, (state, action) => {
        setActivePremise(action.payload);
      });
  },
});

export const { clearList, setActivePremise, removeActivePremise } = premiseSlice.actions;

export default premiseSlice.reducer;
