import { TABLEMAP } from '../constants';
import { Map, Region } from '../../models/mapElement/map';
import axiosInstance from '../networkAxios';

export const postTableMapAPI = (data: Map) => {
  return axiosInstance.put(`${TABLEMAP}`, data);
};

export const getTableMapByPremiseIdAPI = (premiseId: string) => {
  return axiosInstance.get(`${TABLEMAP}/premise/${premiseId}`);
};

export const uploadBackgroundImageAPI = (data: FormData) => {
  return axiosInstance.put(`${TABLEMAP}/backroundImage`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createNewRegionAPI = (data: Region) => {
  return axiosInstance.post(`${TABLEMAP}/region`, data);
};

export const createNewMapAPI = (data: Map) => {
  return axiosInstance.post(`${TABLEMAP}`, data);
};
