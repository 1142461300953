import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginModel } from '../../models/user/loginModel';
import { User } from '../../models/user/user';
import { createManagerAPI, loginUserAPI } from '../../services/api/userApi';

export const loginUser = createAsyncThunk('users/login', async (data: LoginModel, thunkAPI) => {
  return loginUserAPI(data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue(error.response.data);
    });
});

export const createManager = createAsyncThunk(
  'users/createManager',
  async (data: User, thunkAPI) => {
    return createManagerAPI(data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  },
);
