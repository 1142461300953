import { LoginModel } from '../../models/user/loginModel';
import { User } from '../../models/user/user';
import { PaginationRequest } from '../../utils/pagination/pagination';
import { USER } from '../constants';
import axiosInstance, { removeAccessToken } from '../networkAxios';

export const loginUserAPI = (data: LoginModel) => {
  return axiosInstance.post(`${USER}/login`, data);
};

export const getUsersByRole = (role: string, pagination: PaginationRequest) => {
  return axiosInstance.get<User[]>(
    `${USER}/roles/${role}?PageIndex=${pagination.currentPage}&PageSize=${pagination.itemsPerPage}`,
  );
};

export const createManagerAPI = (data: User) => {
  return axiosInstance.post(`${USER}/createManager`, data);
};

export const getManagerApi = (id: string) => {
  return axiosInstance.get(`${USER}/${id}`);
};
