import { Table } from '../models/mapElement/table';

export function newObjectId() {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  const objectId =
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => {
        return Math.floor(Math.random() * 16).toString(16);
      })
      .toLowerCase();

  return objectId;
}

export const createPredefinedTables = () => {
  const templateTable: Table = {
    id: '',
    name: '',
    capacity: 2,
    column: -1,
    row: -1,
    height: 0,
    width: 0,
    rounded: false,
  };
  const predefinedTables: Table[] = [
    // squared
    { ...templateTable, id: newObjectId(), height: 1, width: 1, rounded: false },
    { ...templateTable, id: newObjectId(), height: 2, width: 1, capacity: 4, rounded: false },
    { ...templateTable, id: newObjectId(), height: 3, width: 1, rounded: false },
    { ...templateTable, id: newObjectId(), height: 2, width: 2, capacity: 4, rounded: false },
    { ...templateTable, id: newObjectId(), height: 1, width: 2, capacity: 4, rounded: false },
    { ...templateTable, id: newObjectId(), height: 1, width: 3, rounded: false },
    { ...templateTable, id: newObjectId(), height: 3, width: 3, rounded: false },

    // rounded
    { ...templateTable, id: newObjectId(), height: 1, width: 1, rounded: true },
    { ...templateTable, id: newObjectId(), height: 2, width: 2, rounded: true },
  ];
  return predefinedTables;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // This will return the date in the format "MM/DD/YYYY" or "DD/MM/YYYY" based on locale
};