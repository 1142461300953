import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { formatDate } from '../../utils/helpers';
import { GiveawayResponseModel } from '../../models/giveaway/giveaway';

interface GiveawayTableProps {
  data: GiveawayResponseModel[];
}

const GiveawayTable: React.FC<GiveawayTableProps> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>User Email</TableCell>
            <TableCell>Reservation Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.userId}>
              <TableCell>{row.userId}</TableCell>
              <TableCell>{row.userEmail}</TableCell>
              <TableCell>{formatDate(row.reservationDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GiveawayTable; 