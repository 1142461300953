import { Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const TimeLabel = styled(Form.Label)`
font-size: 8px;
`;

export const DayOfWeek = styled(Form.Label)`
margin: 0 auto;
font-size: 10px;
`;

export const TimeWrapper = styled(Row)`
margin: 2px 1px;
`;

export const SettingsLabel = styled(Form.Label)`
font-size: 10px;
`;