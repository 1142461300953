import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Table } from '../../models/mapElement/table';
import {
  createNewMap,
  createNewRegion,
  getTableMapByPremiseId,
  updateExistingMap,
  uploadBackgroundImage,
} from './mapThunk';
import { Map, Region } from '../../models/mapElement/map';

export interface MapState {
  id: string;
  premiseId: string;
  name: string;
  columns: number;
  mapWidth: number;
  squareSize: number;
  elements: Table[];
  elementsOnMap: Table[];
  image: string;
  occupiedPositions: { id: string; positions: string[] }[];
  elementMoving: boolean;
  activeTable: Table | null;
  region: Region | null;
  regionList: Region[];
  isNewRegion: boolean;
}

const initialState: MapState = {
  id: '',
  columns: 40,
  name: '',
  mapWidth: 800,
  squareSize: 20,
  elements: [],
  elementsOnMap: [],
  occupiedPositions: [],
  elementMoving: false,
  activeTable: null,
  premiseId: '',
  image: '',
  region: null,
  regionList: [],
  isNewRegion: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setActiveMap: (state, action: PayloadAction<Map>) => {
      const map = action.payload;
      state.id = map.id;
      state.premiseId = map.premiseId;
      state.name = map.name;
      state.regionList = map.regions;
    },
    removeActiveMap: state => {
      state.id = '';
      state.name = '';
      state.regionList = [];
    },
    setElements: (state, action: PayloadAction<Table[]>) => {
      state.elements = action.payload;
    },
    setElementMoving: (state, action: PayloadAction<boolean>) => {
      state.elementMoving = action.payload;
    },
    changeColumnNumbers: (state, action: PayloadAction<number>) => {
      state.columns = action.payload;
      if (state.region) {
        state.region.columns = action.payload;
        state.region.rows = action.payload;
      }
    },
    changeMapWidth: (state, action: PayloadAction<number>) => {
      state.mapWidth = action.payload;
    },
    changeSquareSize: (state, action: PayloadAction<number>) => {
      state.squareSize = action.payload;
    },
    addElementOnMap: (state, action: PayloadAction<Table>) => {
      state.elementsOnMap.push(action.payload);
      state.region?.tables.push(action.payload);
    },
    changeElementPositionOnMap: (
      state,
      action: PayloadAction<{ id: string; elementX: number; elementY: number }>,
    ) => {
      const elementOnMapToChange = state.elementsOnMap.find(
        element => element.id === action.payload.id,
      );

      if (elementOnMapToChange) {
        elementOnMapToChange.column = action.payload.elementX;
        elementOnMapToChange.row = action.payload.elementY;
      }
    },
    updateElementOnMapName: (state, action: PayloadAction<Table>) => {
      const elementOnMapToChange = state.elementsOnMap.find(
        element => element.id === action.payload.id,
      );
      if (elementOnMapToChange) {
        elementOnMapToChange.name = action.payload.name;
      }

      const elementInRegionToChange = state.region?.tables.find(
        element => element.id === action.payload.id,
      );
      if (elementInRegionToChange) elementInRegionToChange.name = action.payload.name;
    },
    removeElementOnMap: (state, action: PayloadAction<string>) => {
      state.elementsOnMap = state.elementsOnMap.filter(element => element.id !== action.payload);
      state.elementMoving = false;
      if (state.region)
        state.region.tables = state.region?.tables.filter(element => element.id !== action.payload);
    },
    clearElementsOnMap: state => {
      state.elementsOnMap = [];
      state.occupiedPositions = [];
    },
    addOccupiedPositions: (state, action: PayloadAction<{ id: string; positions: string[] }>) => {
      state.occupiedPositions.push({
        id: action.payload.id,
        positions: [...action.payload.positions],
      });
    },
    removeOccupiedPositions: (state, action: PayloadAction<string>) => {
      state.occupiedPositions = state.occupiedPositions.filter(el => el.id !== action.payload);
    },
    setActiveTable: (state, action: PayloadAction<Table>) => {
      state.activeTable = action.payload;
    },
    removeActiveTable: state => {
      state.activeTable = null;
    },
    addNewElement: (state, action: PayloadAction<Table>) => {
      state.elements.push(action.payload);
    },
    setActiveRegion: (state, action: PayloadAction<Region>) => {
      state.region = action.payload;
      state.columns = action.payload.columns;
      state.image = action.payload.image || '';
      state.elementsOnMap = action.payload.tables;
    },
    removeActiveRegion: state => {
      state.region = null;
    },
    addRegion: (state, action: PayloadAction<Region>) => {
      state.region = action.payload;
      state.columns = action.payload.columns;
      state.image = action.payload.image || '';
      state.elementsOnMap = action.payload.tables;
      setActiveRegion(action.payload);
    },
    changeNewRegion: (state, action: PayloadAction<boolean>) => {
      state.isNewRegion = action.payload;
    },
    removeRegionFromList: (state, action: PayloadAction<string>) => {
      state.regionList.filter(item => item.id !== action.payload);
    },
    updateRegionInList: (state, action: PayloadAction<Region>) => {
      const indexToUpdate = state.regionList.findIndex(r => r.id === action.payload.id);
      state.regionList[indexToUpdate] = action.payload;
    },
    pushRegionToList: (state, action: PayloadAction<Region>) => {
      state.regionList.push(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTableMapByPremiseId.fulfilled, (state, action) => {
        setActiveMap(action.payload);
      })
      .addCase(uploadBackgroundImage.fulfilled, (state, action) => {
        state.image = action.payload;
        if (state.region) {
          state.region.image = action.payload;
        }
      })
      .addCase(updateExistingMap.fulfilled, (state, action) => {
        setActiveMap(action.payload);
      })
      .addCase(createNewMap.fulfilled, (state, action) => {
        setActiveMap(action.payload);
      })

      .addCase(createNewRegion.fulfilled, (state, action) => {
        state.region = action.payload;
        state.regionList.push(action.payload);
      });
  },
});

export const {
  setActiveMap,
  removeActiveMap,
  setElements,
  setElementMoving,
  changeColumnNumbers,
  changeMapWidth,
  changeSquareSize,
  addElementOnMap,
  changeElementPositionOnMap,
  removeElementOnMap,
  clearElementsOnMap,
  addOccupiedPositions,
  removeOccupiedPositions,
  updateElementOnMapName,
  setActiveTable,
  removeActiveTable,
  addNewElement,
  setActiveRegion,
  removeActiveRegion,
  addRegion,
  changeNewRegion,
  removeRegionFromList,
  updateRegionInList,
  pushRegionToList,
} = mapSlice.actions;

export default mapSlice.reducer;
