import React from 'react';
import { Circles } from 'react-loader-spinner';
import { LoadingWrapperContainer } from './LoadingWrapper.style';

const LoadingWrapper = () => {
  return (
    <LoadingWrapperContainer>
      <div className='spinner-border' style={{ width: '3rem', height: '3rem' }} role='status'>
        {/* <span className='sr-only'>Loading...</span> */}
      </div>
    </LoadingWrapperContainer>
  );
};

export default LoadingWrapper;
