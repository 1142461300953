import styled from 'styled-components';
import Select from 'react-select';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { Column, Row } from '../../../utils/layout';
import colors from '../../../utils/colors';

export const MapScreeContainer = styled(Column)`
  width: 100%;
  height: 100%;
`;

export const MapScreenHeader = styled(Column)`
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: center;
`;

export const MapScreenContent = styled(Row)`
  width: 100%;
  height: 100%;
`;
export const HowToContainer = styled(Column)`
  width: auto;
  padding: 1rem;
  background-color: lightblue;
`;

export const HowToLabel = styled(Column)`
  border-radius: 10px;
  border: 1px solid black;
  padding: 5px;
  width: 10rem;
`;
export const MapSideBar = styled(Row)`
  width: 20%;
  padding: 1rem;
`;
export const MapContentContainer = styled(Column)`
  width: 80%;
  height: 100%;
  border-left: 1px solid lightgray;
  justify-content: flex-start;
  padding: 1rem;
  max-width: 80%;
  height: 100%;
`;
export const MapActionButtonsContainer = styled(Column)`
  padding: 1rem;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
`;

export const OverlayTriggerContainer = styled(OverlayTrigger)`
  align-self: flex-end;
`;
export const MapInformationContainer = styled(Column)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const MapInputContainer = styled(Column)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const MapInputWithLabel = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const InfoLabel = styled.i`
  text-align: left;
  font-size: 12px;
`;
export const Label = styled.div`
  text-align: left;
  font-size: 15px;
`;
export const MapInput = styled.input`
  width: 5rem;
  margin-left: 1rem;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  border: 1px solid ${colors.warning};
`;

export const MapSelectorContainer = styled(Column)`
  width: 100%;
  display: block;
  align-items: center;
`;

export const RegionsDataContainer = styled(Column)`
  width: 100%;
  height: 100%;
  display: block;
  background-color: lightyellow;
  align-items: center;
  justify-content: flex-start;
`;

export const RegionButtonsContainer = styled(Row)`
  width: 100%;
  justify-content: space-around;
`;
export const MapsDataContainer = styled(Column)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const RegionInput = styled(MapInput)`
  width: 10rem;
`;
export const FormWrapper = styled(Column)`
  height: 100%;
  width: 100%;
  gap: 1rem;
  margin-bottom: 2rem;
  justify-content: flex-start;
`;

export const SquaresWrapper = styled(Row)`
  width: 10rem;
`;

export const RestaurantLabel = styled.span`
  font-size: 20px;
  text-align: left;
  font-weight: bold;
`;

export const ImagePreviewContainer = styled(Column)`
  margin-top: 2rem;
  width: 100%;
  height: auto;
  border: 1px solid ${colors.lightgray};
  border-radius: 5px;
`;
export const ClearImageLabel = styled(Label)`
  align-self: flex-end;
  cursor: pointer;
  font-size: 12px;
`;
export const PreviewImage = styled.img`
  width: 100%;
  margin-bottom: 1rem;
  object-fit: contain;
`;

export const ElementLabel = styled.p`
  text-align: center;
  font-size: 15px;
  margin: 0;
`;
export const MapButton = styled(Button)<{ color?: string }>`
  margin-block: 3px;
  height: fit-content;
  width: fit-content;
  background-color: ${props => props.color};
`;

export const ElementsContainer = styled(Row)`
  overflow-x: scroll;
  align-items: flex-start;
  min-height: max-content;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: ${colors.lightgray};
  padding-bottom: 2rem;
  padding-top: 0.5rem;
  padding-inline-start: 1rem;
`;

export const ElementsWrapper = styled(Row)`
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  height: max-content;
  min-height: max-content;
`;

export const RegionFormContainer = styled(Column)`
  width: 100%;
  margin-bottom: 20px;
`;

export const RegionDropdownContainer = styled(Row)`
  width: 100%;
`;

export const RegionInformation = styled(Column)`
  width: 100%;
  padding: 5px;
`;
export const AddRegionContainer = styled(Column)`
  width: 20rem;
  align-items: flex-start;
  justify-content: center;
`;
