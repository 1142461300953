import React, { FC } from 'react';
import { Table } from '../../models/mapElement/table';
import {
  ElementBlock,
  ElementPickContainer,
  RemoveTempTableButton,
  TempTableInfoContainer,
  TempTableLabel,
} from './TemplateTable.style';
import MapElement from '../../components/dnd/MapElement';

type Props = {
  element: Table;
  onRemove: (element: Table) => void;
};

const TemplateTable: FC<Props> = ({ element, onRemove }) => {
  return (
    <ElementPickContainer>
      <ElementBlock>
        <RemoveTempTableButton onClick={() => onRemove(element)}>x</RemoveTempTableButton>
        <TempTableInfoContainer>
          <TempTableLabel>
            {element.width}x{element.height}
          </TempTableLabel>
        </TempTableInfoContainer>
        <MapElement key={element.id} mapElement={element} id={element.id} isTemp />
        <TempTableLabel>👤 {element.capacity}</TempTableLabel>
      </ElementBlock>
    </ElementPickContainer>
  );
};

export default TemplateTable;
