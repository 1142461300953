import React, { FC, useEffect, useRef } from 'react';
import { hideModal } from '../../redux/modal/modalSlice';
import { useAppDispatch } from '../../redux/hooks';
import { ModalOverlay, ModalContent, ModalWrapper, CloseModal } from './CustomModal.style';
import { selectModal } from '../../redux/modal/modalSelector';
import { selectIsLoading } from '../../redux/message/MessageSelector';

const CustomModal: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.keyCode === 27) {
        dispatch(hideModal());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch]); // Ensure effect runs only once or when dispatch changes

  const appModal = selectModal();
  return (
    <ModalOverlay show={appModal.show}>
      <ModalWrapper>
        <CloseModal onClick={() => dispatch(hideModal())}>x</CloseModal>
        <ModalContent>{appModal.content}</ModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default CustomModal;
