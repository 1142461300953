/* eslint-disable no-plusplus */
import React, { FC, memo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Table } from '../../models/mapElement/table';
import {
  MapElementWrapper,
  MapTableCapacityLabel,
  MapTableNameLabel,
  TableLabel,
  TablePopupInfo,
} from './Dnd.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectMap } from '../../redux/map/mapSelectors';
import {
  removeElementOnMap,
  removeOccupiedPositions,
  setActiveTable,
  setElementMoving,
} from '../../redux/map/mapSlice';
import { TempTableLabel } from '../../utils/TemplateTable/TemplateTable.style';

type Props = {
  mapElement: Table;
  id: string;
  isTemp?: boolean;
};

const MapElement: FC<Props> = ({ mapElement, id, isTemp = false }) => {
  const { squareSize, elementsOnMap, occupiedPositions } = useAppSelector(() => selectMap());
  const [showInfo, setShowInfo] = useState(false);
  const dispatch = useAppDispatch();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'mapElement',
      item: () => {
        dispatch(setElementMoving(true));
        dispatch(removeOccupiedPositions(id));
        return { id };
      },
      collect: monitor => ({
        item: monitor.getItem(),
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          // dropped outside
          dispatch(removeElementOnMap(item.id));
        }
      },
    }),
    [elementsOnMap, occupiedPositions],
  );

  const elementWidth = mapElement.width * squareSize;
  const elementHeight = mapElement.height * squareSize;

  const handleElementPressed = () => {
    if (elementsOnMap.find(e => e.id === mapElement.id)) {
      dispatch(setActiveTable(mapElement));
    }
  };

  return (
    <MapElementWrapper
      ref={drag}
      width={elementWidth}
      height={elementHeight}
      onClick={handleElementPressed}
      rounded={mapElement.rounded}
      onMouseOver={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
    >
      <MapTableNameLabel>{mapElement.name}</MapTableNameLabel>
      {!isTemp && <MapTableCapacityLabel>👤 {mapElement.capacity}</MapTableCapacityLabel>}
      {showInfo && !isTemp && (
        <TablePopupInfo>
          <TempTableLabel> {mapElement.name}</TempTableLabel>
          <TempTableLabel>
            {mapElement.width}x{mapElement.height}
          </TempTableLabel>
          <TempTableLabel>👤 {mapElement.capacity}</TempTableLabel>
        </TablePopupInfo>
      )}
    </MapElementWrapper>
  );
};

export default memo(MapElement);
