import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import Geocode from 'react-geocode';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Location } from '../../models/premise/location';
import { LocalMarker } from './marker';

const center = {
  lat: 44.4199192,
  lng: 20.6563783,
};

Geocode.setApiKey('AIzaSyBSRH7dupfxuWZzOWPQcNTEUgRc76yQY4w');

type Prop = {
  handleLocation: (location: Location) => void;
  location: Location;
};

export const Map = (prop: Prop) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBSRH7dupfxuWZzOWPQcNTEUgRc76yQY4w',
    libraries: ['places'],
  });

  const [marker, setMarker] = useState<NonNullable<LocalMarker>>(
    prop?.location?.lat && prop?.location?.lng
      ? ({ lat: prop?.location?.lat, lng: prop?.location?.lng } as unknown as LocalMarker)
      : center,
  );
  const [address, setAddress] = React.useState<string>(
    prop?.location?.street && prop?.location?.number && prop?.location?.city
      ? `${prop?.location?.street},${prop?.location?.number},${prop?.location?.city}, Serbia`
      : '',
  );

  const renderMap = () => {
    return (
      <div>
        <PlacesAutocomplete
          handleMarker={(lngLat: LocalMarker): void => {
            setMarker(lngLat);
          }}
          handleLocation={prop.handleLocation}
          address={address}
        />

        <GoogleMap
          mapContainerStyle={{ height: '300px', width: '100%' }}
          zoom={6}
          center={center}
          onClick={event => {
            if (event.latLng?.lat() && event.latLng?.lng()) {
              setMarker({ lat: event.latLng?.lat(), lng: event.latLng?.lng() });
              Geocode.fromLatLng(
                event.latLng?.lat().toString(),
                event.latLng?.lng().toString(),
              ).then(
                response => {
                  setAddress(response.results[0].formatted_address);
                  const location = parseLocation(
                    response.results[0].formatted_address,
                    response.results[0].geometry.location.lat,
                    response.results[0].geometry.location.lng,
                  );
                  prop.handleLocation(location);
                },
                error => {
                  console.error(error);
                },
              );
            }
          }}
        >
          <Marker key={1} position={{ lat: marker.lat, lng: marker.lng }} />
        </GoogleMap>
      </div>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div />;
};

type Props = {
  handleMarker: (lngLat: LocalMarker) => void;
  handleLocation: (location: Location) => void;
  address: string;
};

const PlacesAutocomplete = (props: Props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  useEffect(() => {
    setValue(props.address);
  }, [props.address]);

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: any) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then(results => {
        const { lat, lng } = getLatLng(results[0]);
        props.handleMarker({ lat, lng });
        const location = parseLocation(description, lat.toString(), lng.toString());
        props.handleLocation(location);
      });
    };

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)} role='presentation'>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder='Premise location?'
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

const parseLocation = (geocodeLocation: any, lat: string, lng: string): Location => {
  const splitedLocation = geocodeLocation.split(',');
  const lastEmptySpace = splitedLocation[0].lastIndexOf(' ');
  const street = splitedLocation[0].slice(0, lastEmptySpace);
  const number = splitedLocation[0].slice(lastEmptySpace + 1);
  const location: Location = { street, number, city: splitedLocation[1], lat, lng } as Location;
  return location;
};
