import React, { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarComponent from '../../components/sidebar/SidebarComponent';
import { ContentContainer, HostAdminPageContainer, SideBarContainer } from './HostAdmin.style';

const HostAdmin: FC = () => {
  return (
    <HostAdminPageContainer>
      <SideBarContainer>
        <SidebarComponent />
      </SideBarContainer>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </HostAdminPageContainer>
  );
};

export default memo(HostAdmin);
