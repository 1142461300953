import { PayloadAction, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

interface MessageSlice {
  active: boolean;
  value: string;
  code: string;
  type: string;
  loading: boolean;
}

const initialState: MessageSlice = {
  active: false,
  value: '',
  code: '',
  type: '',
  loading: false,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    hideMessagePopup: state => {
      state.active = false;
    },
    showMessagePopup: state => {
      state.active = true;
    },
    setMessage: (state, action: PayloadAction<MessageSlice>) => {
      state = action.payload;
    },
    clearMessage: state => {
      state = initialState;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isRejected, (state, action) => {
      const message = action.payload as any;
      console.log(action.payload);
      if (message) {
        state.value = typeof message === 'object' ? message.message : message;
      } else {
        state.value = 'Unknown error occured';
      }
      state.active = true;
      state.loading = false;
    });
    builder.addMatcher(isPending, (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isFulfilled, state => {
      state.loading = false;
    });
  },
});

export const { hideMessagePopup, showMessagePopup, setMessage, clearMessage } =
  messageSlice.actions;

export default messageSlice.reducer;
