/* eslint-disable no-plusplus */
import React, { FC, memo } from 'react';
import { useDrop } from 'react-dnd';
import { Table } from '../../models/mapElement/table';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectMap } from '../../redux/map/mapSelectors';
import {
  addElementOnMap,
  addOccupiedPositions,
  changeElementPositionOnMap,
  setActiveTable,
  setElementMoving,
} from '../../redux/map/mapSlice';
import { SquareOverlay, SquareWrapper } from './Dnd.styled';
import MapElement from './MapElement';
import { newObjectId } from '../../utils/helpers';

type Props = {
  squarePosition: { x: number; y: number };
};

const MapSquare: FC<Props> = ({ squarePosition }) => {
  const { squareSize, elements, elementsOnMap, occupiedPositions } = useAppSelector(() =>
    selectMap(),
  );
  const dispatch = useAppDispatch();

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'mapElement',
      drop: (monitor: any) => moveElement(monitor.id, squarePosition.x, squarePosition.y),
      canDrop: (monitor: any) => checkElement(monitor.id, squarePosition.x, squarePosition.y),
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [elementsOnMap, occupiedPositions, squarePosition.x, squarePosition.y],
  );

  const moveElement = (id: string, elementX: number, elementY: number) => {
    dispatch(setElementMoving(false));

    const elementToChange = elements.find(element => element.id === id);

    if (elementToChange) {
      const tempElement = { ...elementToChange };
      tempElement.id = newObjectId();

      tempElement.column = elementX;
      tempElement.row = elementY;

      dispatch(addElementOnMap(tempElement));

      occupyPositions(tempElement.id, elementToChange, elementX, elementY);

      if (tempElement?.name?.length === 0) {
        dispatch(setActiveTable(tempElement));
      }
    } else {
      dispatch(changeElementPositionOnMap({ id, elementX, elementY }));

      const elementOnMap = elementsOnMap.find(el => el.id === id);
      if (elementOnMap) {
        occupyPositions(id, elementOnMap, elementX, elementY);

        if (elementOnMap?.name?.length === 0) {
          dispatch(setActiveTable(elementOnMap));
        }
      }
    }
  };

  const occupyPositions = (id: string, mapElement: Table, positionX: number, positionY: number) => {
    const horizontalSquareNum = mapElement.width + 1;
    const verticalSquareNum = mapElement.height + 1;

    const occupy = [];
    for (let i = -1; i < horizontalSquareNum; i++) {
      for (let j = -1; j < verticalSquareNum; j++) {
        occupy.push((positionX + i).toString().concat((positionY + j).toString()));
      }
    }

    dispatch(addOccupiedPositions({ id, positions: [...occupy] }));
  };

  const checkElement = (id: string, positionX: number, positionY: number) => {
    const element = elements.find(el => el.id === id);

    if (element) {
      return canDropElement(element, positionX, positionY);
    }

    const elementOnMap = elementsOnMap.find(el => el.id === id);

    if (elementOnMap) {
      return canDropElement(elementOnMap, positionX, positionY);
    }

    return false;
  };

  const canDropElement = (element: Table, positionX: number, positionY: number) => {
    const positionIds: string[] = [];

    const horizontalSquareNum = element.width;
    const verticalSquareNum = element.height;

    for (let i = 0; i < horizontalSquareNum; i++) {
      for (let j = 0; j < verticalSquareNum; j++) {
        positionIds.push((positionX + i).toString().concat((positionY + j).toString()));
      }
    }

    let elementFound = false;

    occupiedPositions.forEach(el => {
      if (el.positions.some(r => positionIds.indexOf(r) >= 0)) {
        elementFound = true;
        return null;
      }
    });

    return !elementFound;
  };

  const getElement = () => {
    const elementData = elementsOnMap.find(
      elementData =>
        elementData.column === squarePosition.x && elementData.row === squarePosition.y,
    );

    if (elementData) {
      return <MapElement key={elementData.id} mapElement={elementData} id={elementData.id} />;
    }
    return null;
  };

  return (
    <SquareWrapper ref={drop}>
      {getElement()}
      {isOver && !canDrop && <SquareOverlay page={squareSize} color='red' />}
      {isOver && canDrop && <SquareOverlay page={squareSize} color='green' />}
    </SquareWrapper>
  );
};

export default memo(MapSquare);
