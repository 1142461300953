import Compressor from "compressorjs";

export const compressImage = (
    file: File,
    quality: number = 0.9,
    maxWidth: number = 1500
): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        const compressor = new Compressor(file, {
            quality,
            maxWidth,
            success(blob) {
                resolve(blob);
            },
            error(err) {
                reject(err);
            },
        });
    });
};