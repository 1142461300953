import { check } from 'prettier';
import React, { FC } from 'react';
import { Label } from '../../../views/host-admin/map/Map.style';

type Props = {
  checked: boolean;
  onChange: () => void;
  label?: string;
};

const CustomCheckBox: FC<Props> = ({ checked, onChange, label }) => {
  return (
    <div className='form-check'>
      <input
        className='form-check-input'
        onChange={() => onChange()}
        type='checkbox'
        value=''
        checked={checked}
      />
      <Label>{label}</Label>
    </div>
  );
};
export default CustomCheckBox;
