import React, { FC, useEffect, useRef, useState } from 'react';
import { Popover } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import RestaurantMap from '../../../components/dnd/RestaurantMap';
import { VerticalDivider } from '../../../components/utils/table.styled';
import TemplateTable from '../../../utils/TemplateTable/TemplateTable';
import colors from '../../../utils/colors';
import LoadingWrapper from '../../../utils/loadingWrapper/LoadingWrapper';
import {
  HowToLabel,
  ElementsContainer,
  MapButton,
  ElementsWrapper,
  MapActionButtonsContainer,
  HowToContainer,
  OverlayTriggerContainer,
  MapContentContainer,
} from './Map.style';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setElementMoving, setElements } from '../../../redux/map/mapSlice';
import { selectElementMoving, selectMap } from '../../../redux/map/mapSelectors';
import { selectIsLoading } from '../../../redux/message/MessageSelector';
import { createPredefinedTables } from '../../../utils/helpers';
import { selectActivePremise } from '../../../redux/premise/premiseSelector';
import { Table } from '../../../models/mapElement/table';
import TableForm from '../../../components/utils/TableForm/TableForm';
import { showModal } from '../../../redux/modal/modalSlice';

const MapContent: FC = () => {
  const dispatch = useAppDispatch();
  const { image, elements } = useAppSelector(() => selectMap());
  const moving = selectElementMoving();
  const premise = selectActivePremise();
  const [img, setImg] = useState<string>();
  const { activeTable } = useAppSelector(() => selectMap());

  const transformRef = useRef<any>(null);

  useEffect(() => {
    transformRef.current && transformRef.current?.zoomOut(0.3);

    if (premise) {
      dispatch(setElements(createPredefinedTables()));
      fetchImage();
    }

    // move map on ctrl down logic
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey) {
        dispatch(setElementMoving(true));
      }
    };
    const onKeyUp = () => {
      dispatch(setElementMoving(false));
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  useEffect(() => {
    activeTable && dispatch(showModal(<TableForm table={activeTable} type='edit' />));
  }, [activeTable]);

  const fetchImage = async () => {
    const res = await fetch(image);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImg(imageObjectURL);
  };

  const handleCreateNewTableClicked = () => {
    dispatch(showModal(<TableForm />));
  };

  const handleRemoveTemplateTable = (element: Table) => {
    dispatch(setElements(elements.filter(obj => obj !== element)));
  };

  const HowToInformation = (
    <Popover id='popover-positioned-right' title='How to use: '>
      <HowToContainer>
        - Klijent šalje sliku tlocrta svog restorana u dimenzijama 800x800. Sa svim stolovima koje
        zeli da doda na mapi i kotama za veličinu samog restorana. <br />
        - Nas dizajner ce da od toga napravi novu sliku za pozadinu koja ce biti dizajnirana onako
        kako mi osmislimo da bude uniformno na svim restoranima. Mi dodajemo stolove na osnovu slike
        klijentea. <br />- Velicina jednog kvadrata uvek predstavlja 1 realni metar. I preračunava
        se na osnovu odnosa 800px i ukuopne širine/dužine objekta
      </HowToContainer>
    </Popover>
  );
  return (
    <MapContentContainer>
      <TransformWrapper ref={transformRef} wheel={{ wheelDisabled: true }} disabled={!moving}>
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <ElementsContainer>
              <MapButton onClick={handleCreateNewTableClicked} color={colors.confirm}>
                +
              </MapButton>
              {elements && (
                <ElementsWrapper>
                  <VerticalDivider color={colors.danger} />
                  {elements.map(element => (
                    <TemplateTable element={element} onRemove={handleRemoveTemplateTable} />
                  ))}
                </ElementsWrapper>
              )}
            </ElementsContainer>
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
                cursor: moving ? 'grab' : 'default',
                border: `2px solid ${colors.danger}`,
                backgroundColor: colors.lightgray,
              }}
            >
              <RestaurantMap />
            </TransformComponent>

            <MapActionButtonsContainer>
              <MapButton onClick={() => zoomIn(0.3)}>+</MapButton>
              <MapButton onClick={() => zoomOut(0.3)}>-</MapButton>
              <MapButton onClick={() => resetTransform()}>[ ]</MapButton>
            </MapActionButtonsContainer>
          </>
        )}
      </TransformWrapper>
    </MapContentContainer>
  );
};

export default MapContent;
