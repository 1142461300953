import { LoadingIndicator } from 'react-select/dist/declarations/src/components/indicators';
import React, { FC, useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import useWindowDimensions, { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectActiveRegion, selectMap, selectRegionList } from '../../../redux/map/mapSelectors';
import {
  changeMapWidth,
  changeSquareSize,
  changeColumnNumbers,
  removeActiveRegion,
  changeNewRegion,
  removeRegionFromList,
  updateRegionInList,
  pushRegionToList,
  removeActiveMap,
  setActiveMap,
} from '../../../redux/map/mapSlice';
import {
  getTableMapByPremiseId,
  updateExistingMap,
  uploadBackgroundImage,
} from '../../../redux/map/mapThunk';
import { Column, Row } from '../../../utils/layout';
import {
  ClearImageLabel,
  FormWrapper,
  ImagePreviewContainer,
  InfoLabel,
  Label,
  MapInformationContainer,
  MapInput,
  MapInputContainer,
  MapInputWithLabel,
  PreviewImage,
  RestaurantLabel,
} from './Map.style';
import { selectActivePremise } from '../../../redux/premise/premiseSelector';
import { Premise } from '../../../models/premise/premise';
import { CustomButton, HorizontalDivider } from '../../../components/utils/table.styled';
import colors from '../../../utils/colors';
import { MapButtonContainer } from '../../../components/dnd/Dnd.styled';
import { hideModal, showModal } from '../../../redux/modal/modalSlice';
import ConfirmationDialog from '../../../utils/confirmationDialog/confirmationDialog';
import { selectIsLoading } from '../../../redux/message/MessageSelector';
import LoadingWrapper from '../../../utils/loadingWrapper/LoadingWrapper';
import { Region } from '../../../models/mapElement/map';
import AddRegion from './AddRegion';

const MapForm: FC = () => {
  const dispatch = useAppDispatch();
  const { width, height } = useWindowDimensions();
  const { id, name, columns, squareSize, mapWidth, isNewRegion } = useAppSelector(() =>
    selectMap(),
  );
  const regions = selectRegionList();
  const premise = selectActivePremise() || ({} as Premise);
  const activeRegion = selectActiveRegion();
  const [totalColumns, setTotalColumns] = useState(columns); // is always same as rows
  const [backgroundImage, setBackgroundImage] = useState<any>();

  const isLoading = selectIsLoading();

  useEffect(() => {
    isLoading && dispatch(showModal(<LoadingWrapper />));
  }, [isLoading]);

  useEffect(() => {
    setTotalColumns(columns);
  }, [activeRegion]);

  useEffect(() => {
    dispatch(changeSquareSize(mapWidth / totalColumns));
    dispatch(changeMapWidth(width * 0.4));
  }, [width, height]);

  useEffect(() => {
    dispatch(changeColumnNumbers(totalColumns));
    dispatch(changeSquareSize(mapWidth / totalColumns));
    dispatch(changeMapWidth(width * 0.4));
  }, [totalColumns]);

  const handleSaveRegion = () => {
    if (activeRegion) {
      if (regions.some(r => r.id === activeRegion.id)) {
        updateRegion();
      } else {
        addNewRegion();
      }
    }
  };
  const updateRegion = () => {
    const newRegionList = [...regions];
    if (activeRegion) {
      const indexToUpdate = newRegionList.findIndex(r => r.id === activeRegion.id);
      newRegionList[indexToUpdate] = activeRegion;
    }
    updateMapRegionList(newRegionList);
  };
  const addNewRegion = () => {
    const newRegionList = [...regions];
    if (activeRegion) {
      newRegionList.push(activeRegion);
    }
    updateMapRegionList(newRegionList);
  };

  const updateMapRegionList = (regions: Region[]) => {
    dispatch(
      updateExistingMap({
        id,
        premiseId: premise?.id,
        regions,
        name,
      }),
    )
      .unwrap()
      .then(map => {
        dispatch(showModal(<Label>Region saved successfully</Label>));
        dispatch(removeActiveRegion());
        dispatch(setActiveMap(map));
      })
      .catch((err: any) => console.log(err));
  };

  const handleSquareNumChange = (event: any) => {
    setTotalColumns(Number(event.target.value));
  };

  useEffect(() => {
    if (backgroundImage) {
      dispatch(
        showModal(
          <ImagePreviewContainer>
            <ClearImageLabel onClick={() => setBackgroundImage(null)}>❌</ClearImageLabel>
            <PreviewImage src={URL.createObjectURL(backgroundImage)} alt='' />
            <CustomButton color={colors.confirm} onClick={handleUploadImage}>
              Save
            </CustomButton>
          </ImagePreviewContainer>,
        ),
      );
    } else {
      dispatch(hideModal());
    }
  }, [backgroundImage]);

  const handleChooseImage = (event: any) => {
    setBackgroundImage(event.target.files[0]);
  };

  const handleUploadImage = () => {
    const formData = new FormData();
    formData.append('image', backgroundImage);
    formData.append('regionId', activeRegion?.id || '');
    dispatch(uploadBackgroundImage(formData))
      .unwrap()
      .then((result: any) => {
        dispatch(showModal(<Label>Image changed successfully</Label>));
        if (result) setBackgroundImage(null);
      });
  };

  const handleCancelPressed = () => {
    if (isNewRegion) {
      dispatch(
        showModal(
          <ConfirmationDialog
            title='Are you sure? New Region will be lost.'
            onConfirm={confirmCancelAddingNewRegion}
          />,
        ),
      );
    } else {
      dispatch(
        showModal(
          <ConfirmationDialog
            title='Are you sure you want to stop editing Region?'
            onConfirm={confirmRevertingChanges}
          />,
        ),
      );
    }
  };
  const confirmRevertingChanges = () => {
    dispatch(removeActiveRegion());
    dispatch(hideModal());
  };

  const confirmCancelAddingNewRegion = () => {
    dispatch(removeActiveRegion());
    dispatch(removeRegionFromList(activeRegion?.id || ''));
    dispatch(changeNewRegion(false));
    dispatch(hideModal());
  };

  return (
    <FormWrapper>
      {activeRegion && (
        <MapInformationContainer>
          <Column>
            <Row>
              <RestaurantLabel>{activeRegion.name}</RestaurantLabel>{' '}
              <MdEdit size={20} onClick={() => dispatch(showModal(<AddRegion />))} />
            </Row>
            {/* <AddRegion /> */}
            <MapInputContainer>
              <MapInputWithLabel>
                <Label>Map Size: </Label>
                <Row>
                  <MapInput
                    type='number'
                    min={10}
                    value={totalColumns}
                    onChange={handleSquareNumChange}
                  />
                  m
                </Row>
              </MapInputWithLabel>
              <InfoLabel>
                *number of columns/rows. Represents total height/width of the Premise in meters
              </InfoLabel>
            </MapInputContainer>
            <MapInputContainer>
              <MapInputWithLabel>
                <Label>Square size: </Label>
                <Row>
                  <MapInput type='number' disabled value={squareSize} />
                  px
                </Row>
              </MapInputWithLabel>
              <InfoLabel>*one square represents one square meter </InfoLabel>
            </MapInputContainer>
            <HorizontalDivider />
            <MapInputContainer>
              <Label>Select background image:</Label>
              <input
                style={{ width: '100%' }}
                className='btn btn-primary'
                type='file'
                accept='image/png, image/jpeg'
                onChange={handleChooseImage}
              />
            </MapInputContainer>
          </Column>

          <MapButtonContainer>
            <CustomButton color={colors.warning} onClick={handleCancelPressed}>
              Cancel
            </CustomButton>
            <CustomButton color={colors.confirm} onClick={handleSaveRegion}>
              Save Changes
            </CustomButton>
          </MapButtonContainer>
        </MapInformationContainer>
      )}
    </FormWrapper>
  );
};

export default MapForm;
