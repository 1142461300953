import React, { FC, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { selectActiveRegion, selectMap, selectRegionList } from '../../../redux/map/mapSelectors';
import TableComponent from '../../../components/utils/table';
import MapForm from './MapForm';
import {
  Label,
  MapScreeContainer,
  MapScreenContent,
  MapScreenHeader,
  MapSelectorContainer,
  MapSideBar,
  MapsDataContainer,
  RegionButtonsContainer,
  RegionsDataContainer,
  RestaurantLabel,
} from './Map.style';
import { selectActivePremise, selectPremiseMaps } from '../../../redux/premise/premiseSelector';
import MapContent from './MapContent';
import { useAppDispatch } from '../../../redux/hooks';
import { CustomButton } from '../../../components/utils/table.styled';
import { Pagination } from '../../../utils/pagination/pagination';
import {
  setActiveRegion,
  removeActiveRegion,
  setActiveMap,
  removeActiveMap,
} from '../../../redux/map/mapSlice';
import { hideModal, showModal } from '../../../redux/modal/modalSlice';
import AddRegion from './AddRegion';
import colors from '../../../utils/colors';
import { Map, Region } from '../../../models/mapElement/map';
import { TableData } from '../../../types/tableData';
import { changeMainTableMap, getPremiseMaps } from '../../../redux/premise/premiseThunk';
import AddMap from './AddMap';
import { ChangeMainTableMapModel } from '../../../models/premise/premise';

const MapComponent: FC = () => {
  const premise = selectActivePremise();
  const activeRegion = selectActiveRegion();
  const dispatch = useAppDispatch();
  const regions = selectRegionList();
  const premiseMaps = selectPremiseMaps();
  const activeMap = selectMap();
  const mapList = selectPremiseMaps();
  const [showRegionsData, setShowRegionsData] = useState(false);

  const [requestedPage, setRequestedPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>({
    itemsPerPage: 20,
    totalPages: 0,
    currentPage: 1,
  });

  const changePagination = (page: number) => {
    setRequestedPage(page);
  };

  useEffect(() => {
    premise && dispatch(getPremiseMaps(premise.id));
    dispatch(removeActiveRegion());
  }, []);

  const createNewRegion = () => {
    dispatch(showModal(<AddRegion />));
  };

  const createNewMap = () => {
    dispatch(showModal(<AddMap />));
  };

  const regionsData: TableData = {
    headers: { name: 'Name' },
    data: regions,
    actions: [
      {
        name: 'Remove',
        function: (region: Region) => {
          // axiosInstance.delete(`${PREMISE}/${premise.id}`);
          alert('deleting region...');
        },
        buttonColor: colors.danger,
      },
      {
        name: 'Edit',
        function: (region: Region) => {
          dispatch(setActiveRegion(region));
        },
        buttonColor: colors.info,
      },
    ],
  };

  const mapsData: TableData = {
    headers: { name: 'Name' },
    data: premiseMaps,
    actions: [
      {
        name: 'Select',
        function: (map: Map) => {
          handleSelectMapPressed(map);
        },
        buttonColor: colors.confirm,
      },
      {
        name: 'Remove',
        function: (map: Map) => {
          alert('deleting map...');
        },
        buttonColor: colors.danger,
      },
      {
        name: 'Edit',
        function: (map: Map) => {
          dispatch(setActiveMap(map));
          dispatch(showModal(<AddMap />));
        },
        buttonColor: colors.info,
      },
      {
        name: 'Set as Main',
        function: (map: Map) => {
          setAsMainMap(map);
        },
        buttonColor: colors.lightgray,
      },
    ],
  };

  const handleSelectMapPressed = (map: Map) => {
    dispatch(setActiveMap(map));
    setShowRegionsData(true);
  };

  const closeRegionsPressed = () => {
    dispatch(removeActiveMap());
    setShowRegionsData(false);
  };

  const setAsMainMap = (mapToUpdate: Map) => {
    dispatch(
      changeMainTableMap({
        premiseId: mapToUpdate.premiseId,
        tableMapId: mapToUpdate.id,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(hideModal());
      });
  };

  return (
    <MapScreeContainer>
      {premise && (
        <>
          <MapScreenHeader>
            {
              <RestaurantLabel>
                {premise.name} Map {activeMap.name} management
              </RestaurantLabel>
            }
          </MapScreenHeader>
          <MapScreenContent>
            <DndProvider backend={HTML5Backend}>
              {activeRegion ? (
                <>
                  <MapSideBar>
                    <MapForm />
                  </MapSideBar>
                  <MapContent />
                </>
              ) : (
                <MapSelectorContainer>
                  {!showRegionsData && (
                    <MapsDataContainer>
                      <RestaurantLabel>Maps: </RestaurantLabel>
                      <CustomButton color={colors.confirm} onClick={createNewMap}>
                        Add Map
                      </CustomButton>
                      {mapList?.length > 0 ? (
                        <TableComponent
                          data={mapsData}
                          pagination={pagination}
                          changePagination={changePagination}
                        />
                      ) : (
                        <Label>Premise has no maps</Label>
                      )}
                    </MapsDataContainer>
                  )}
                  {showRegionsData && (
                    <RegionsDataContainer>
                      <RegionButtonsContainer>
                        <CustomButton color={colors.confirm} onClick={createNewRegion}>
                          Add Region
                        </CustomButton>
                        <CustomButton color={colors.lightgray} onClick={closeRegionsPressed}>
                          Close Regions
                        </CustomButton>
                      </RegionButtonsContainer>
                      <RestaurantLabel>Regions: </RestaurantLabel>
                      {regions && (
                        <TableComponent
                          data={regionsData}
                          pagination={pagination}
                          changePagination={changePagination}
                        />
                      )}
                    </RegionsDataContainer>
                  )}
                </MapSelectorContainer>
              )}
            </DndProvider>
          </MapScreenContent>
        </>
      )}
    </MapScreeContainer>
  );
};

export default MapComponent;
