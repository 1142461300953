import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChangeMainTableMapModel, Premise } from '../../models/premise/premise';
import {
  changeMainTableMapAPI,
  getAllPremisesApi,
  getPremiseMapsApi,
  postPremiseApi,
  putPremiseApi,
} from '../../services/api/premiseApi';
import { PaginationRequest } from '../../utils/pagination/pagination';

export const getAllPremises = createAsyncThunk(
  'premise/getAll',
  async (pagination: PaginationRequest, thunkAPI) => {
    return getAllPremisesApi(pagination)
      .then(response => response)
      .catch(error => thunkAPI.rejectWithValue(error.response.data));
  },
);

export const getPremiseMaps = createAsyncThunk(
  'premise/getMaps',
  async (premiseId: string, thunkAPI) => {
    return getPremiseMapsApi(premiseId)
      .then(response => response)
      .catch(error => thunkAPI.rejectWithValue(error.response.data));
  },
);

export const changeMainTableMap = createAsyncThunk(
  'premise/changeMainMap',
  async (data: ChangeMainTableMapModel, thunkAPI) => {
    return changeMainTableMapAPI(data)
      .then(response => response.data)
      .catch(error => thunkAPI.rejectWithValue(error.response.data));
  },
);

export const createPremise = createAsyncThunk('premise/create', async (data: Premise, thunkAPI) => {
  return postPremiseApi(data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue(error.response.data);
    });
});

export const updatePremise = createAsyncThunk('premise/update', async (data: Premise, thunkAPI) => {
  return putPremiseApi(data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue(error.response.data);
    });
});
