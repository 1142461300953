import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { Column } from '../layout';
import { CustomButton } from '../../components/utils/table.styled';

export const ModalOverlay = styled(Modal)`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled(Column)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  padding: 5rem;
`;

export const CloseModal = styled(CustomButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  background-color: gray;
`;
