import styled from "styled-components";

export const AnalyticsPageContainer = styled.div`
  padding: 20px;
`;

export const AnalyticsTitle = styled.h1`
  margin-bottom: 20px;
`;

export const AnalyticsContent = styled.div`
  display: flex;
  flex-direction: column;
`;