import update from 'immutability-helper';
import React, { FC, useCallback, useEffect } from 'react';
import { Image } from '../../models/premise/image';
import { Card } from './card';

const style = {
  width: '100%',
};

type Props = {
  menuItems: Image[];
  handleImageReorder: (items: Image[]) => void;
};

interface ExtendedImage extends Image {
  deleted?: boolean;
}

export interface ContainerState {
  cards: ExtendedImage[];
}

export const ContainerDnd: FC<Props> = props => {
  useEffect(() => {
    setCards(props.menuItems);
  }, [props.menuItems]);

  const [cards, setCards] = React.useState<ExtendedImage[]>(props.menuItems);

  const reorderArray = (prevCards: ExtendedImage[], dragIndex: number, hoverIndex: number) => {
    const array = update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex] as ExtendedImage],
      ],
    });

    props.handleImageReorder(array);
    return array;
  };

  const handleDelete = useCallback(
    (index: number) => {
      setCards(prevCards => {
        const updatedCards = [...prevCards];
        updatedCards[index] = { ...updatedCards[index], order: -1, deleted: true };
        props.handleImageReorder(updatedCards);
        return updatedCards;
      });
    },
    [props.handleImageReorder],
  );

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: ExtendedImage[]) => reorderArray(prevCards, dragIndex, hoverIndex));
  }, []);

  const renderCard = useCallback(
    (card: ExtendedImage, index: number) => {
      let img;
      if (card.imageFile) {
        img = card.imageFile;
      } else {
        img = card.path;
      }
      return (
        <Card
          key={card.order}
          index={index}
          order={card.order}
          image={img}
          moveCard={moveCard}
          handleDelete={() => handleDelete(index)}
        />
      );
    },
    [handleDelete, moveCard],
  );

  return <div style={style}>{cards?.map((card, i) => !card.deleted && renderCard(card, i))}</div>;
};
