import React, { FC, memo, useMemo, useState } from 'react';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Action, TableData } from '../../types/tableData';
import { Pagination } from '../../utils/pagination/pagination';
import { Actions, CustomButton } from './table.styled';

type Props = {
  data: TableData;
  pagination: Pagination;
  changePagination: (page: number) => void;
};

const TableComponent: FC<Props> = ({ data, pagination, changePagination }) => {
  const keys = Object.keys(data.headers);
  const pageCount = pagination.totalPages;
  const handlePageClick = (event: any) => {
    const currentPage: number = (event.selected as number) + 1;
    changePagination(currentPage);
  };

  return (
    <>
      <Table bgcolor='lightyellow' responsive>
        <thead>
          <tr>
            {Array.from({ length: keys.length }).map((_, i) => (
              <th style={{ textAlign: 'left' }} key={i}>
                {data.headers[keys[i]]}
              </th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((obj: any, i: number) => (
            <tr key={i}>
              {keys.map((property: any, j) => (
                <td style={{ textAlign: 'left' }} key={i + j}>
                  {obj[property]}
                </td>
              ))}
              <Actions>
                {data.actions.map((action: Action, k) => (
                  <CustomButton
                    onClick={() => action.function(obj)}
                    key={i + k}
                    color={action.buttonColor}
                  >
                    {action.name}
                  </CustomButton>
                ))}
              </Actions>
            </tr>
          ))}
        </tbody>
      </Table>
      <ReactPaginate
        breakLabel='...'
        nextLabel='>'
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel='<'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        previousClassName='page-item'
        previousLinkClassName='page-link'
        nextClassName='page-item'
        nextLinkClassName='page-link'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        containerClassName='pagination'
        activeClassName='active'
        renderOnZeroPageCount={() => null}
      />
    </>
  );
};

export default memo(TableComponent);
