import React, { FC, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  DASHBOARD,
  HowItWorks_Customer,
  HowItWorks_Manager,
  MAP,
  PREMISE,
  USER,
  GIVEAWAY,
} from '../../services/routes';

const SidebarComponent: FC = () => {
  return (
    <div className='h-100 d-flex flex-column flex-shrink-0 p-3 bg-light' style={{ width: '100%' }}>
      <a
        href='/'
        className='d-flex align-items-center mb-3 mb-md-0 link-dark text-decoration-nones'
      >
        <span className='mx-auto fs-4 text-center '>MyHost</span>
      </a>
      <hr />
      <ul className='nav nav-pills flex-column mb-auto'>
        <li className='nav-item'>
          <Link className='nav-link link-dark' to={DASHBOARD}>
            Dashboard
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link link-dark' to={PREMISE}>
            Premises
          </Link>
        </li>
        <li>
          <Link className='nav-link link-dark' to={USER}>
            Users
          </Link>
        </li>
        <li>
          <Link className='nav-link link-dark' to={HowItWorks_Customer}>
            HowItWorks Customer
          </Link>
        </li>
        <li>
          <Link className='nav-link link-dark' to={HowItWorks_Manager}>
            HowItWorks Manager
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link link-dark' to={GIVEAWAY}>
            Giveaway
          </Link>
        </li>
      </ul>
      <hr />
    </div>
  );
};

export default memo(SidebarComponent);
