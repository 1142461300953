import { createAsyncThunk } from '@reduxjs/toolkit';
import { Map, Region } from '../../models/mapElement/map';
import {
  createNewMapAPI,
  createNewRegionAPI,
  getTableMapByPremiseIdAPI,
  postTableMapAPI,
  uploadBackgroundImageAPI,
} from '../../services/api/mapApi';

export const updateExistingMap = createAsyncThunk(
  'tableMap/update',
  async (data: Map, thunkAPI) => {
    return postTableMapAPI(data)
      .then(response => {
        console.log('thunk response: ', response.data);
        return response.data;
      })
      .catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  },
);

export const createNewMap = createAsyncThunk('tableMap/create', async (data: Map, thunkAPI) => {
  return createNewMapAPI(data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue(error.response.data);
    });
});

export const getTableMapByPremiseId = createAsyncThunk(
  'tableMap/premise',
  async (premiseId: string, thunkAPI) => {
    return getTableMapByPremiseIdAPI(premiseId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  },
);

export const uploadBackgroundImage = createAsyncThunk(
  'tableMap/uploadBackgroundImage',
  async (data: FormData, thunkAPI) => {
    return uploadBackgroundImageAPI(data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  },
);

export const createNewRegion = createAsyncThunk(
  'tableMap/createRegion',
  async (data: Region, thunkAPI) => {
    return createNewRegionAPI(data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return thunkAPI.rejectWithValue(error.response.data);
      });
  },
);
