const yellow = '#FFC529';
const white = '#ffffff';
const red = '#FF3C30';
const green = '#3a8a2e';
const gray = '#4B4C4E';
const black = '#121212';
const transparentOverlay = 'rgba(18, 18, 18, 0.7)';
const transparentOverlayLighter = 'rgba(18, 18, 18, 0.4)';
const transparentBackground = 'rgba(18, 18, 18, 0.7)';
const warning = '#b5821b';
const confirm = '#64a373';
const info = '#7592c9';
const lightgray = '#c4c7cc';
const danger = '#9e4741';

const common = {
  yellow,
  red,
  green,
  gray,
  white,
  black,
  transparentOverlay,
  transparentOverlayLighter,
  warning,
  info,
  confirm,
  danger,
  lightgray,
  transparentBackground,
};
const dark = {
  ...common,
  text: '#ffffff',
  text_shaded: '#c7c7c75e',
  background: '#121212',
  background_shaded: '#1b1c1e',
  // transparentOverlay: 'rgba(16, 16, 16, 0.85)',
};

const light = {
  ...common,
  text: '#0D0D0D',
  text_shaded: '#1b1c1e45',
  background: '#ffffff',
  background_shaded: '#f1f1f1',
  // transparentOverlay: 'rgba(184, 184, 184, 0.5)',
  green: '#3a8a2e',
};
const colors = dark;
export default colors;
