import React, { FC, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { PremiseTimeSettings } from '../../models/premise/premiseTimeSettings';
import { stringToNumber } from '../../utils/converters/stringToNumber';
import { DayOfWeek, SettingsLabel, TimeLabel, TimeWrapper } from './premise.time.component.styled';

type Props = {
  handlePremiseTime: (premiseTimeSettings: PremiseTimeSettings) => void;
  timeSettings: PremiseTimeSettings;
};

const PremiseTime: FC<Props> = ({ handlePremiseTime, timeSettings }) => {
  const [premiseTimeSettings, setPremiseTimeSettings] =
    React.useState<PremiseTimeSettings>(timeSettings);

  useEffect(() => {
    setPremiseTimeSettings(timeSettings);
  }, [timeSettings]);

  useEffect(() => {
    if (premiseTimeSettings) {
      handlePremiseTime(premiseTimeSettings);
    }
  }, [premiseTimeSettings]);

  const handleOpeningWorkHours = (
    selectedDay: number,
    selectedTime: string,
    isOpeningTime: boolean,
  ) => {
    const selectedTimeInMinutes: number = selectedTime.includes('-') ? -1 : +selectedTime;
    const updatedWorkHours = premiseTimeSettings?.workHours
      ? [...premiseTimeSettings.workHours]
      : [];
    const existingWorkHourIndex = updatedWorkHours.findIndex(x => x.dayOfWeek === selectedDay);

    if (existingWorkHourIndex !== -1) {
      const existingWorkHour = updatedWorkHours[existingWorkHourIndex];
      const updatedWorkHour = {
        ...existingWorkHour,
        openingTimeInMinutes: isOpeningTime
          ? selectedTimeInMinutes
          : existingWorkHour.openingTimeInMinutes,
        closingTimeInMinutes: isOpeningTime
          ? existingWorkHour.closingTimeInMinutes
          : selectedTimeInMinutes,
      };
      updatedWorkHours[existingWorkHourIndex] = updatedWorkHour;
    } else {
      const newWorkHour = {
        dayOfWeek: selectedDay,
        openingTimeInMinutes: isOpeningTime ? selectedTimeInMinutes : -1,
        closingTimeInMinutes: isOpeningTime ? -1 : selectedTimeInMinutes,
      };
      updatedWorkHours.push(newWorkHour);
    }

    setPremiseTimeSettings({ ...premiseTimeSettings, workHours: updatedWorkHours });
  };

  return (
    <Row>
      {[1, 2, 3, 4, 5, 6, 0].map(day => (
        <Col key={day}>
          <TimeWrapper>
            <DayOfWeek>{DayOfWeek[day]}</DayOfWeek>
            <TimeLabel>OT in minutes </TimeLabel>
            <Form.Control
              type='text'
              onChange={e => handleOpeningWorkHours(day, e.target.value.trim(), true)}
              value={
                premiseTimeSettings?.workHours?.find(x => x.dayOfWeek === day)
                  ?.openingTimeInMinutes || ''
              }
            />
          </TimeWrapper>
          <TimeWrapper>
            <TimeLabel>CT in minutes</TimeLabel>
            <Form.Control
              type='text'
              onChange={e => handleOpeningWorkHours(day, e.target.value.trim(), false)}
              value={
                premiseTimeSettings?.workHours?.find(x => x.dayOfWeek === day)
                  ?.closingTimeInMinutes || ''
              }
            />
          </TimeWrapper>
        </Col>
      ))}
      <Row>
        <Col>
          <TimeWrapper>
            <SettingsLabel>Min start time res from now </SettingsLabel>
            <Form.Control
              type='text'
              onChange={e =>
                setPremiseTimeSettings({
                  ...premiseTimeSettings,
                  minStartTimeFromNowInMinutes: stringToNumber(e.target.value),
                })
              }
              value={premiseTimeSettings?.minStartTimeFromNowInMinutes || ''}
            />
          </TimeWrapper>
        </Col>
        <Col>
          <TimeWrapper>
            <SettingsLabel>Min duration res in minutes</SettingsLabel>
            <Form.Control
              type='text'
              onChange={e =>
                setPremiseTimeSettings({
                  ...premiseTimeSettings,
                  minDurationInMinutes: stringToNumber(e.target.value),
                })
              }
              value={premiseTimeSettings?.minDurationInMinutes || ''}
            />
          </TimeWrapper>
        </Col>
        <Col>
          <TimeWrapper>
            <SettingsLabel>Max duration res in minutes</SettingsLabel>
            <Form.Control
              type='text'
              onChange={e =>
                setPremiseTimeSettings({
                  ...premiseTimeSettings,
                  maxDurationInMinutes: stringToNumber(e.target.value),
                })
              }
              value={premiseTimeSettings?.maxDurationInMinutes || ''}
            />
          </TimeWrapper>
        </Col>
        <Col>
          <TimeWrapper>
            <SettingsLabel>Time interval in picker</SettingsLabel>
            <Form.Control
              type='text'
              onChange={e =>
                setPremiseTimeSettings({
                  ...premiseTimeSettings,
                  selectableMinuteInterval: stringToNumber(e.target.value),
                })
              }
              value={premiseTimeSettings?.selectableMinuteInterval || ''}
            />
          </TimeWrapper>
        </Col>
      </Row>
    </Row>
  );
};

export default PremiseTime;
