import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Table } from '../../../models/mapElement/table';
import {
  FormContainer,
  NewTableForm,
  TableFormButtonsContainer,
  TableFormContainer,
  TableFormWrapper,
  TablePreview,
} from './TableForm.style';
import { CustomButton, HorizontalDivider } from '../table.styled';
import colors from '../../../utils/colors';
import {
  InfoLabel,
  Label,
  MapInput,
  MapInputContainer,
  MapInputWithLabel,
} from '../../../views/host-admin/map/Map.style';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { newObjectId } from '../../../utils/helpers';
import { MapElementWrapper } from '../../dnd/Dnd.styled';
import { selectMap } from '../../../redux/map/mapSelectors';
import {
  addNewElement,
  removeActiveTable,
  updateElementOnMapName,
} from '../../../redux/map/mapSlice';
import { hideModal } from '../../../redux/modal/modalSlice';

type Props = {
  onSave?: (table: Table) => void;
  onCancel?: () => void;
  table?: Table;
  type?: string;
};

const TableForm: FC<Props> = ({ onSave, onCancel, table, type }) => {
  const dispatch = useAppDispatch();

  const { squareSize } = useAppSelector(() => selectMap());
  const [name, setName] = useState(table?.name || '');
  const [width, setWidth] = useState(table?.width || 3);
  const [height, setHeight] = useState(table?.width || 2);
  const [capacity, setCapacity] = useState(table?.capacity || 0);
  const [valid, setvalid] = useState(false);
  const [rounded, setRounded] = useState(false);

  useEffect(() => {
    validate();
  }, [name, width, height, capacity]);

  const validate = () => {
    if (table) {
      setvalid(!!name);
    } else if (width > 0 && height > 0 && capacity > 0) setvalid(true);
  };

  const handleNameChange = (e: any) => setName(e.target.value);
  const handleCapacityChange = (e: any) => setCapacity(e.target.value);
  const handleWidthChange = (e: any) => setWidth(e.target.value);
  const handleHeightChange = (e: any) => setHeight(e.target.value);

  const handleSaveClicked = () => {
    const newTable: Table = table
      ? { ...table, name }
      : {
          column: -1,
          id: newObjectId(),
          name: '',
          row: -1,
          capacity,
          height,
          width,
          rounded,
        };
    type === 'edit' ? updateTableName(newTable) : createNewTable(newTable);
  };

  const handleCancelClicked = () => {
    dispatch(hideModal());
    dispatch(removeActiveTable());
  };

  const updateTableName = (table: Table) => {
    dispatch(removeActiveTable());
    dispatch(updateElementOnMapName(table));
    dispatch(hideModal());
  };

  const createNewTable = (table: Table) => {
    dispatch(addNewElement(table));
    dispatch(hideModal());
  };

  return (
    <TableFormContainer>
      <TableFormWrapper>
        {!table ? (
          <NewTableForm>
            <FormContainer>
              <MapInputWithLabel>
                <Label>Capacity: </Label>
                <MapInput type='number' min={0} value={capacity} onChange={handleCapacityChange} />
              </MapInputWithLabel>
              <MapInputWithLabel>
                <Label>Width: </Label>
                <MapInput type='number' min={1} value={width} onChange={handleWidthChange} />
              </MapInputWithLabel>
              <MapInputWithLabel>
                <Label>Height: </Label>
                <MapInput type='number' min={1} value={height} onChange={handleHeightChange} />
              </MapInputWithLabel>
              <InfoLabel>*represented in rows and columns </InfoLabel>
              <MapInputWithLabel>
                <Label>Rounded: </Label>
                <MapInput type='checkbox' onChange={() => setRounded(!rounded)} />
              </MapInputWithLabel>
            </FormContainer>
            <TablePreview>
              <MapElementWrapper
                height={height * squareSize}
                width={width * squareSize}
                rounded={rounded}
              />
            </TablePreview>
          </NewTableForm>
        ) : (
          <MapInputContainer>
            <MapInputWithLabel>
              <Label>Name: </Label>
              <MapInput
                style={{ width: '10rem' }}
                type='text'
                value={name}
                onChange={handleNameChange}
              />
            </MapInputWithLabel>
            <InfoLabel>*Name cannot be empty</InfoLabel>
          </MapInputContainer>
        )}

        <HorizontalDivider />
        <TableFormButtonsContainer>
          <CustomButton color={colors.warning} onClick={handleCancelClicked}>
            Cancel
          </CustomButton>
          <CustomButton disabled={!valid} color={colors.confirm} onClick={handleSaveClicked}>
            Save
          </CustomButton>
        </TableFormButtonsContainer>
      </TableFormWrapper>
    </TableFormContainer>
  );
};

export default TableForm;
