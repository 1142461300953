import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectMap } from '../../redux/map/mapSelectors';
import {
  ClearMapButton,
  ColumnDimensionsLabel,
  DimensionLine,
  RestaurantMapContainer,
  RestaurantWrapper,
} from './Dnd.styled';
import MapSquare from './MapSquare';
import colors from '../../utils/colors';
import { CustomButton } from '../utils/table.styled';
import { clearElementsOnMap } from '../../redux/map/mapSlice';

export const SQUARE_SIZE = 20;

const MapRestaurant: FC = () => {
  const dispatch = useAppDispatch();

  const { columns, squareSize, mapWidth, image } = useAppSelector(() => selectMap());

  const renderSquare = (index: number) => {
    const maxSquaresInLine = columns;

    const x = index % maxSquaresInLine;
    const y = Math.floor(index / maxSquaresInLine);

    const left = (x * squareSize).toString().concat('px');
    const top = (y * squareSize).toString().concat('px');

    return (
      <div
        key={index}
        style={{
          width: squareSize.toString().concat('px'),
          height: squareSize.toString().concat('px'),
          position: 'absolute',
          top,
          left,
        }}
      >
        <MapSquare squarePosition={{ x, y }} />
      </div>
    );
  };

  const squares: JSX.Element[] = [];
  for (let i = 0; i < columns * columns; i += 1) {
    squares.push(renderSquare(i));
  }
  const handleClearMap = () => dispatch(clearElementsOnMap());

  return (
    <RestaurantMapContainer>
      <ClearMapButton color={colors.danger} onClick={handleClearMap}>
        Clear
      </ClearMapButton>
      <ColumnDimensionsLabel>
        {'< '}
        {columns}m{' >'}
        <DimensionLine />
      </ColumnDimensionsLabel>

      <RestaurantWrapper
        width={mapWidth}
        height={mapWidth}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
        }}
      >
        {squares}
      </RestaurantWrapper>
    </RestaurantMapContainer>
  );
};

export default MapRestaurant;
