import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export const DropdownList = styled(Dropdown)`
    margin: calc(var(--bs-gutter-x) * .5) !important;
`;

export const RoundButton = styled(Button)`
    margin: 0.2rem;
    font-size: 12px;
    width: 34px;
`;

export const Separator = styled.hr`
    margin: 20px 1px 20px 1px;
    color: blue;
`;

export const Error = styled (Form.Text)`
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
`
