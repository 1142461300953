import React, { useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box } from '@mui/material';
import { ReservationAnalyticsResponseModel } from '../../models/analytics/reservationAnalytics';

interface ReservationAnalyticsTableProps {
  data: ReservationAnalyticsResponseModel[];
}

const ReservationAnalyticsTable: React.FC<ReservationAnalyticsTableProps> = ({ data }) => {
  const [filters, setFilters] = useState({
    reservationId: '',
    userName: '',
    userSurname: '',
    userEmail: '',
    status: '',
  });

  const filteredData = useMemo(() => {
    return data.filter((row) => {
      return Object.entries(filters).every(([key, value]) => {
        return row[key as keyof ReservationAnalyticsResponseModel]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      });
    });
  }, [data, filters]);

  const handleFilterChange = (column: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [column]: event.target.value });
  };

  const columns = [
    { key: 'reservationId', label: 'Reservation ID' },
    { key: 'userName', label: 'User Name' },
    { key: 'userSurname', label: 'User Surname' },
    { key: 'userEmail', label: 'User Email' },
    { key: 'status', label: 'Status' },
    { key: 'amountSpent', label: 'Amount Spent', align: 'right' as const, noFilter: true },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell 
                key={column.key} 
                align={column.align as "left" | "center" | "right" | "justify" | "inherit" | undefined}
                sx={{
                  borderBottom: '2px solid rgba(224, 224, 224, 1)',
                  fontWeight: 'bold'
                }}
              >
                <Box sx={{ minHeight: column.noFilter ? '56px' : 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                  {!column.noFilter && (
                    <TextField
                      fullWidth
                      variant="standard"
                      placeholder={`Search ${column.label}`}
                      value={filters[column.key as keyof typeof filters] || ''}
                      onChange={handleFilterChange(column.key)}
                      sx={{ mb: 2 }}
                    />
                  )}
                  {column.label}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row) => (
            <TableRow key={row.reservationId}>
              {columns.map((column) => (
                <TableCell key={column.key} align={column.align}>
                  {column.key === 'amountSpent'
                    ? `${row[column.key].toFixed(2)} RSD`
                    : row[column.key as keyof ReservationAnalyticsResponseModel]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReservationAnalyticsTable;
