import React from 'react';
import styled from 'styled-components';
import {
  LandingPageDescription,
  LandingPageHeading,
  LandingPageImage,
  LandingPageSection,
  LandingScreenContainer,
  LandingScreenTitle,
} from './HowItWorks.style';

type Language = 'en' | 'sr';

const TEXTS_CUSTOMER = {
  en: {
    title: 'How MyHost Works',
    reservationHeading: 'Quick and Easy Reservation',
    reservationDescription:
      'The MyHost app allows you to make reservations in just a few clicks, whether it’s for restaurants, cafes, pubs, or other hospitality venues. Each venue in the app includes interior photos, a brief description, and menu images, making it easier for you to choose when making a reservation. Once you select a venue, you can precisely choose the exact table where you want to sit. The reservation is confirmed through a simple process where the manager just needs to approve your request. Find the perfect venue easily using the search function or various filters.',
    levelsHeading: 'Levels and Points',
    levelsDescription:
      'After each successful reservation, you have the option to scan the QR code on the bill and collect points. These points are awarded based on the amount you spent and allow you to advance to higher levels with special benefits and offers. The bill must be scanned within one hour after the reservation, and it must be issued at the same venue during the time of your reservation.',
    eventsHeading: 'MyHost Events',
    eventsDescription:
      'Within the MyHost app, there is a dedicated page for events in your city—whether it’s concerts, parties, promotions, or similar gatherings. All these events can be reserved through the MyHost app, quickly and easily, in just a few clicks.',
  },
  sr: {
    title: 'Kako radi MyHost?',
    reservationHeading: 'Brza i Jednostavna Rezervacija',
    reservationDescription:
      'MyHost aplikacija omogućava vam rezervaciju u samo nekoliko klikova, bilo da se radi o restoranima, kafićima, pubovima ili drugim ugostiteljskim objektima. Svaki lokal u aplikaciji sadrži slike enterijera, kratak opis, kao i slike menija, što vam olakšava izbor prilikom rezervacije. Nakon što odaberete lokal, možete precizno izabrati tačan sto na kojem želite da sedite. Rezervacija se potvrđuje jednostavnim postupkom, gde menadžer samo treba da odobri vaš zahtev. Pronađite savršen lokal lako putem pretrage ili korišćenjem različitih filtera.',
    levelsHeading: 'Nivoi i Bodovi',
    levelsDescription:
      'Nakon svake uspešne rezervacije, imate mogućnost da skenirate QR kod na računu i na taj način prikupite bodove. Ovi bodovi se dodeljuju u skladu sa iznosom koji ste potrošili, a omogućavaju vam da prelazite na više nivoe sa posebnim pogodnostima i ponudama. Račun je potrebno skenirati u roku od jednog sata nakon rezervacije, i on mora biti izdat u istom lokalu i za vreme trajanja vaše rezervacije.',
    eventsHeading: 'MyHost Događaji',
    eventsDescription:
      'Unutar MyHost aplikacije postoji posebna stranica posvećena događajima u vašem gradu – bilo da je reč o koncertima, žurkama, promocijama i sličnim događanjima. Sve ove događaje možete rezervisati putem MyHost aplikacije, brzo i jednostavno, u samo nekoliko klikova.',
  },
};

const HowItWorksCustomer = () => {
  const params = new URLSearchParams(window.location.search);
  const lang: Language = (params.get('lang') as Language) || 'sr';

  const texts = TEXTS_CUSTOMER[lang];

  return (
    <LandingScreenContainer>
      <LandingScreenTitle>{texts.title}</LandingScreenTitle>

      <LandingPageSection>
        <LandingPageHeading>{texts.reservationHeading}</LandingPageHeading>
        <LandingPageImage
          src='https://as1.ftcdn.net/v2/jpg/08/85/75/96/1000_F_885759682_XF4NMpsVbjNJzMnalsiTTgwOxJGHSpDz.jpg'
          alt={texts.reservationHeading}
        />
        <LandingPageDescription>{texts.reservationDescription}</LandingPageDescription>
      </LandingPageSection>

      <LandingPageSection>
        <LandingPageHeading>{texts.levelsHeading}</LandingPageHeading>
        <LandingPageImage
          src='https://as1.ftcdn.net/v2/jpg/08/85/75/96/1000_F_885759682_XF4NMpsVbjNJzMnalsiTTgwOxJGHSpDz.jpg'
          alt={texts.levelsHeading}
        />
        <LandingPageDescription>{texts.levelsDescription}</LandingPageDescription>
      </LandingPageSection>

      <LandingPageSection>
        <LandingPageHeading>{texts.eventsHeading}</LandingPageHeading>
        <LandingPageImage
          src='https://as1.ftcdn.net/v2/jpg/08/85/75/96/1000_F_885759682_XF4NMpsVbjNJzMnalsiTTgwOxJGHSpDz.jpg'
          alt={texts.eventsHeading}
        />
        <LandingPageDescription>{texts.eventsDescription}</LandingPageDescription>
      </LandingPageSection>
    </LandingScreenContainer>
  );
};

export default HowItWorksCustomer;
