import styled from 'styled-components';
import { Column, Row } from '../../utils/layout';
import colors from '../../utils/colors';
import { CustomButton } from '../utils/table.styled';

export const RestaurantMapContainer = styled(Column)`
  width: 100%;
`;

export const ClearMapButton = styled(CustomButton)`
  position: absolute;
  right: 0;
  top: -1rem;
`;

export const RestaurantWrapper = styled.div<{
  width: number;
  height: number;
  backgroundImage?: string;
}>`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: 2px solid black;
  position: relative;
  background-image: url(backgroundImage);
`;

export const MapButtonContainer = styled(Column)``;

export const ColumnDimensionsLabel = styled(Column)`
  width: 100%;
  justify-content: center;
  color: grey;
`;

export const DimensionLine = styled.div`
  height: 1px;
  background-color: grey;
  color: grey;
  width: 100%;
  margin-bottom: 1rem;
`;

export const SquareWrapper = styled.div<{ noTopBorder?: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-right: 1px dotted ${colors.info};
  border-bottom: 1px dotted ${colors.info};
  z-index: 8;
  > div {
    position: absolute;
  }
`;

export const MapElementWrapper = styled(Column)<{ width: any; height: any; rounded?: boolean }>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${colors.info};
  border-radius: ${props => (props.rounded ? 50 : 5)}px;
  border: 1px solid ${colors.danger};
  justify-content: center;
  align-items: center;
  cursor: grab;
`;

export const MapElementImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const SquareOverlay = styled.div<{ page: number; color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.page}px;
  height: ${props => props.page}px;
  z-index: 1;
  opacity: 0.5;
  background-color: ${props => props.color};
`;

export const TableLabel = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.danger};
  margin-bottom: 0;
  text-align: left;
`;
export const MapTableNameLabel = styled(TableLabel)`
  position: absolute;
  top: -20px;
`;

export const MapTableCapacityLabel = styled(TableLabel)`
  position: absolute;
  right: -30px;
`;
export const TablePopupInfo = styled(Column)`
  background-color: ${colors.lightgray};
  position: absolute;
  top: 10px;
  right: -5.5rem;
  width: 5rem;
  height: auto;
  padding-block: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${colors.black};
  border-radius: 5px;
  z-index: 9999;
`;
