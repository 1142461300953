import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReservationAnalyticsResponseModel } from '../../../models/analytics/reservationAnalytics';
import { getReservationAnalytics } from '../../../services/api/analyticsApi';
import ReservationAnalyticsTable from '../../../components/analytics/ReservationAnalyticsTable';
import { AnalyticsContent, AnalyticsPageContainer, AnalyticsTitle } from './ReservationAnalytics.style';

const ReservationAnalytics: FC = () => {
  const { premiseId } = useParams<{ premiseId: string }>();
  const [analyticsData, setAnalyticsData] = useState<ReservationAnalyticsResponseModel[]>([]);

  useEffect(() => {
    fetchReservationAnalytics();
  }, [premiseId]);

  const fetchReservationAnalytics = async () => {
    if (!premiseId) return;
    try {
      const data = await getReservationAnalytics(premiseId);
      setAnalyticsData(data);
    } catch (error) {
      console.error('Error fetching reservation analytics:', error);
    }
  };
  const premiseName = analyticsData.length > 0 ? analyticsData[0].premiseName : 'Loading...';

  return (
    <AnalyticsPageContainer>
      <AnalyticsTitle>Reservation Analytics for {premiseName}</AnalyticsTitle>
      <AnalyticsContent>
        <ReservationAnalyticsTable data={analyticsData} />
      </AnalyticsContent>
    </AnalyticsPageContainer>
  );
};

export default ReservationAnalytics;
