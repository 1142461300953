import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';
import { UserModel } from '../../models/user/userModel';
import { removeAccessToken, setAccessToken } from '../../services/networkAxios';
import storage from '../../utils/storage';
import { loginUser } from './userThunk';

export interface UserState {
  user: UserModel | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null | unknown;
}

const initialState: UserState = {
  user: null,
  loading: 'idle',
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signOutUser: state => {
      storage.clearAll();
      removeAccessToken();
      state = initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginUser.pending, state => {
        state.loading = 'pending';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = 'idle';

        const response = action.payload;
        const user = jwt_decode(response.token) as UserModel;

        state.user = user;

        const userId = storage.getUserId();
        if (userId !== user.id) {
          storage.clearAll();
          storage.setUserId(user.id);
        }
        storage.setToken(response.token);
        setAccessToken(response.token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = 'idle';
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export const { signOutUser } = userSlice.actions;
export default userSlice.reducer;
