import React from 'react';
import styled from 'styled-components';
import {
  LandingScreenContainer,
  LandingScreenTitle,
  LandingPageSection,
  LandingPageHeading,
  LandingPageImage,
  LandingPageDescription,
} from './HowItWorks.style';

type Language = 'en' | 'sr';

const TEXTS_MANAGER = {
  en: {
    title: 'How MyHost Works',
    reservationHeading: 'Quick and Easy Reservation',
    reservationDescription: (
      <p>
        When a user submits a reservation request, the manager immediately receives a notification.
        There are two types of reservations:
        <p>
          <br />
          <strong>A la carte:</strong> Users choose an exact seating location in the restaurant
          during regular hours.
        </p>
        <br />
        <strong>Events:</strong> Users select only the type of table (table, high seating, booth),
        but not the exact location within the venue.
        <br /> The manager can accept or reject the reservation, with the option to contact the user
        for additional questions. Each reservation includes user information (name, surname, phone
        number), as well as the date, time, and selected table. If the manager does not mark the
        user as Absent, the system will automatically assume that the user has arrived once the
        reservation begins.
      </p>
    ),
    featuresHeading: 'Manager Features',
    featuresDescription: (
      <ul>
        <li>
          View the restaurant map with table names (by clicking the plus icon on the reservation
          page).
        </li>
        <li>Block specific tables on the restaurant map.</li>
        <li>Create reservations directly on the restaurant map.</li>
        <li>
          Track users who do not show up for reservations and block them if it becomes a recurring
          issue.
        </li>
        <li>Pause all reservations and disable table selection for a specified period.</li>
      </ul>
    ),
    eventsHeading: 'MyHost Events',
    eventsDescription: (
      <>
        Venues can create events (live music, parties, promotions) that appear on a special page in
        the app. Users can reserve attendance but not the exact table location, only the type of
        table. The manager decides on the seating arrangement of guests during events.
      </>
    ),
  },
  sr: {
    title: 'Kako radi MyHost?',
    reservationHeading: 'Brza i Jednostavna Rezervacija',
    reservationDescription: (
      <>
        Kada korisnik pošalje zahtev za rezervaciju, menadžer odmah dobija notifikaciju. Postoje dva
        tipa rezervacija:
        <br />
        <strong>A la carte:</strong> Korisnici biraju tačno mesto za sedenje u restoranu tokom
        regularnog rada.
        <br />
        <strong>Događaji:</strong> Korisnici biraju samo vrstu stola (sto, visoko sedenje, separe),
        ali ne i tačno mesto u lokalu. <br />
        Menadžer može prihvatiti ili odbiti rezervaciju, uz mogućnost kontaktiranja korisnika za
        dodatna pitanja. Svaka rezervacija uključuje podatke o korisniku (ime, prezime, broj
        telefona), kao i datum, vreme, i odabrani sto. Ako menadžer ne označi korisnika kao Odsutan,
        kada rezervacija počne, sistem će automatski smatrati da je korisnik stigao na rezervaciju.
      </>
    ),
    featuresHeading: 'Funckionalnosti menadžera',
    featuresDescription: (
      <ul>
        <li>
          Prikaz mape restorana sa nazivima stolova (klikom na ikonicu plus na strani rezervacije).
        </li>
        <li>Blokiranje određenih stolova na mapi restorana.</li>
        <li>Kreiranje rezervacija direktno na mapi restorana.</li>
        <li>
          Evidencija korisnika koji se ne pojave na rezervaciji i njihovo blokiranje u slučaju
          ponavljanja.
        </li>
        <li>Pauziranje svih rezervacija i onemogućavanje izbora stolova za određeni period.</li>
      </ul>
    ),
    eventsHeading: 'MyHost događaji',
    eventsDescription: (
      <>
        Lokali mogu kreirati događaje (svirke, žurke, promocije) koji se pojavljuju na posebnoj
        stranici u aplikaciji. Korisnici mogu rezervisati prisustvo, ali ne i tačnu lokaciju stola,
        već samo vrstu stola, dok menadžer odlučuje o rasporedu gostiju.
      </>
    ),
  },
};

const HowItWorksManager = () => {
  const params = new URLSearchParams(window.location.search);
  const lang: Language = (params.get('lang') as Language) || 'sr';

  const texts: any = TEXTS_MANAGER[lang];

  return (
    <LandingScreenContainer>
      <LandingScreenTitle>{texts.title}</LandingScreenTitle>

      <LandingPageSection>
        <LandingPageHeading>{texts.reservationHeading}</LandingPageHeading>
        <LandingPageImage
          src='https://as1.ftcdn.net/v2/jpg/08/85/75/96/1000_F_885759682_XF4NMpsVbjNJzMnalsiTTgwOxJGHSpDz.jpg'
          alt={texts.reservationHeading}
        />
        <LandingPageDescription>{texts.reservationDescription}</LandingPageDescription>
      </LandingPageSection>

      <LandingPageSection>
        <LandingPageHeading>{texts.featuresHeading}</LandingPageHeading>
        <LandingPageImage
          src='https://as1.ftcdn.net/v2/jpg/08/85/75/96/1000_F_885759682_XF4NMpsVbjNJzMnalsiTTgwOxJGHSpDz.jpg'
          alt={texts.featuresHeading}
        />
        <LandingPageDescription>{texts.featuresDescription}</LandingPageDescription>
      </LandingPageSection>

      <LandingPageSection>
        <LandingPageHeading>{texts.eventsHeading}</LandingPageHeading>
        <LandingPageImage
          src='https://as1.ftcdn.net/v2/jpg/08/85/75/96/1000_F_885759682_XF4NMpsVbjNJzMnalsiTTgwOxJGHSpDz.jpg'
          alt={texts.eventsHeading}
        />
        <LandingPageDescription>{texts.eventsDescription}</LandingPageDescription>
      </LandingPageSection>
    </LandingScreenContainer>
  );
};

export default HowItWorksManager;
