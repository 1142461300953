import React, { FC, useState } from 'react';
import { Row } from 'react-bootstrap';
import {
  AddRegionContainer,
  InfoLabel,
  Label,
  MapInput,
  MapInputContainer,
  MapInputWithLabel,
  RegionInput,
} from './Map.style';
import { CustomButton } from '../../../components/utils/table.styled';
import colors from '../../../utils/colors';
import { useAppDispatch } from '../../../redux/hooks';
import { addRegion, changeNewRegion, setActiveRegion } from '../../../redux/map/mapSlice';
import { Region } from '../../../models/mapElement/map';
import { newObjectId } from '../../../utils/helpers';
import { hideModal } from '../../../redux/modal/modalSlice';
import { createNewRegion } from '../../../redux/map/mapThunk';
import { selectActiveRegion, selectMap } from '../../../redux/map/mapSelectors';
import CustomCheckBox from '../../../components/utils/CheckBox/CustomCheckBox';

const AddRegion: FC = () => {
  const [error, setError] = useState('');
  const activeMap = selectMap();
  const activeRegion = selectActiveRegion();
  const [smokingAllowed, setSmokingAllowed] = useState(activeRegion?.smokingAllowed || false);
  const [name, setName] = useState(activeRegion?.name || '');

  const dispatch = useAppDispatch();

  const handleNameChange = (event: any) => {
    setName(event.target.value.toString());
    setError('');
  };

  const saveRegion = () => {
    if (name === '') {
      setError('Name cannot be empty');
      return;
    }
    if (!activeRegion) {
      createRegion();
    } else {
      alert('creating region');

      updateRegion();
    }
  };

  const updateRegion = () => {
    if (activeRegion) {
      dispatch(setActiveRegion({ ...activeRegion, smokingAllowed, name }));
      dispatch(hideModal());
    }
  };

  const createRegion = () => {
    const region: Region = {
      id: newObjectId(),
      columns: 40,
      tableMapId: activeMap.id,
      name,
      rows: 40,
      smokingAllowed,
      tables: [],
    };
    console.log(region);
    dispatch(createNewRegion(region))
      .unwrap()
      .then(() => {
        dispatch(changeNewRegion(true));
        dispatch(hideModal());
      })
      .catch(err => console.log(err));
  };

  return (
    <AddRegionContainer>
      <MapInputContainer>
        <MapInputWithLabel>
          <Label>Region Name: </Label>
          <RegionInput
            type='string'
            placeholder={activeRegion?.name}
            min={10}
            value={name}
            onChange={handleNameChange}
          />
        </MapInputWithLabel>
      </MapInputContainer>
      {error && <InfoLabel style={{ color: 'red' }}>{error}</InfoLabel>}

      <label className='form-check-label'>Smoking allowed</label>
      <CustomCheckBox
        checked={smokingAllowed}
        onChange={() => setSmokingAllowed(!smokingAllowed)}
      />
      <CustomButton color={colors.confirm} onClick={saveRegion}>
        Save
      </CustomButton>
    </AddRegionContainer>
  );
};

export default AddRegion;
