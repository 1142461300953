import React, { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, DashboardPageContainer, AnalyticsSection, AnalyticsTitle, AnalyticsContent, AnalyticsTableWrapper, AnalyticsFilters, } from './Dashboard.style';
import { useAppDispatch } from '../../../redux/hooks';
import { signOutUser } from '../../../redux/user/userSlice';
import { PremiseAnalyticsRequestModel, PremiseAnalyticsResponseModel } from '../../../models/analytics/premiseAnalytics';
import { getPremisesAnalytics } from '../../../services/api/analyticsApi';
import { Status } from '../../../models/reservation/status';
import PremiseAnalyticsTable from '../../../components/analytics/PremiseAnalyticsTable';
import PremiseFilter from '../../../components/analytics/PremiseFilter';
import DateRangeFilter from '../../../components/analytics/DataRangeFilter';
import StatusFilter from '../../../components/analytics/StatusFilter';
import { DatePickerWrapper } from '../giveaway/Giveaway.style';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [analyticsData, setAnalyticsData] = useState<PremiseAnalyticsResponseModel[]>([]);
  const [filters, setFilters] = useState<PremiseAnalyticsRequestModel>({
    PremiseIds: [],
    FromDate: null,
    ToDate: null,
    Statuses: [],
  });

  useEffect(() => {
    fetchAnalyticsData();
  }, [filters]);

  const fetchAnalyticsData = async () => {
    try {
      const data = await getPremisesAnalytics(filters);
      setAnalyticsData(data);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  const handlePremiseFilterChange = (selectedPremises: string[]) => {
    setFilters(prev => ({ ...prev, PremiseIds: selectedPremises }));
  };

  const handleDateRangeChange = (fromDate: Date | null, toDate: Date | null) => {
    setFilters(prev => ({ ...prev, FromDate: fromDate, ToDate: toDate }));
  };

  const handleStatusFilterChange = (selectedStatuses: Status[]) => {
    setFilters(prev => ({ ...prev, Statuses: selectedStatuses }));
  };

  const SignOutHandler = () => {
    dispatch(signOutUser());
    navigate('/');
  };

  return (
    <DashboardPageContainer>
      DASHBOARD
      <ButtonContainer>
        <button onClick={SignOutHandler}>Sign out</button>
      </ButtonContainer>

      <AnalyticsSection>
        <AnalyticsTitle>Premise Analytics</AnalyticsTitle>
        <AnalyticsContent>
          <AnalyticsTableWrapper>
            <PremiseAnalyticsTable data={analyticsData} />
          </AnalyticsTableWrapper>
          <AnalyticsFilters>
            <PremiseFilter onChange={handlePremiseFilterChange} />
            <DatePickerWrapper>
              <DateRangeFilter onChange={handleDateRangeChange} />
            </DatePickerWrapper>
            <StatusFilter onChange={handleStatusFilterChange} />
          </AnalyticsFilters>
        </AnalyticsContent>
      </AnalyticsSection>
    </DashboardPageContainer>
  );
};

export default memo(Dashboard);
