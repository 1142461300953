import React, { FC, memo, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TableComponent from '../../../../components/utils/table';
import { User } from '../../../../models/user/user';
import { getUsersByRole } from '../../../../services/api/userApi';
import axiosInstance from '../../../../services/networkAxios';
import { PREMISE } from '../../../../services/routes';
import { TableData } from '../../../../types/tableData';
import { Pagination, parsePagination } from '../../../../utils/pagination/pagination';
import { AddButton } from '../../premise/get/Premises.styled';

const Premises: FC = () => {
  const roles = [
    { name: 'Admin', value: 0 },
    { name: 'Manager', value: 1 },
    { name: 'Customer', value: 2 },
  ];

  const [user, setUser] = useState<User>();
  const [users, setUsers] = useState<User[]>([]);
  const [role, setRole] = useState<number>(1);
  const [modalShow, setModalShow] = React.useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    itemsPerPage: 20,
    totalPages: 0,
    currentPage: 1,
  });
  const [requestedPage, setRequestedPage] = useState<number>(1);
  const pageSize = 20;

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const changePagination = (page: number) => {
    setRequestedPage(page);
  };

  useEffect(() => {
    getUsersByRole(role.toString(), { itemsPerPage: pageSize, currentPage: requestedPage })
      .then(response => {
        setUsers(response.data);
        setPagination(parsePagination(response.headers['x-pagination']));
      })
      .catch(error => console.log(error));
  }, [requestedPage]);

  const tableData: TableData = {
    headers: { name: 'Name', surname: 'Surname', email: 'Email', role: 'Role' },
    data: users,
    actions: [
      {
        name: 'Remove',
        function: (user: User) => {
          axiosInstance.delete(`${PREMISE}/${user.id}`);
        },
      },
      {
        name: 'Edit',
        function: (user: User) => {
          setUser(user);
          toggleModal();
        },
      },
    ],
  };

  return (
    <Container>
      <h2> Premises </h2>
      <Row>
        <AddButton variant='primary' onClick={() => setModalShow(true)}>
          Add
        </AddButton>
      </Row>
      <hr />
      <Row>
        <TableComponent
          data={tableData}
          pagination={pagination}
          changePagination={changePagination}
        />
      </Row>

      {/* <AddPremise
                show={modalShow}
                onHide={toggleModal}
                premiseModel = {premise}
            /> */}
    </Container>
  );
};

export default memo(Premises);
