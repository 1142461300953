import styled from 'styled-components';
import { Select } from 'antd';
import DatePicker from 'react-datepicker';

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
`;

export const FilterLabel = styled.h3`
  margin-bottom: 8px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const DateRangeFilterContainer = styled.div`
  margin-bottom: 100px;
`;

export const DateRangeTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const DatePickersContainer = styled.div`
  display: flex;
  gap: 20px; // Adds space between the From and To sections
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1; // Makes both From and To sections equal width
`;

export const DatePickerLabel = styled.label`
  margin-right: 10px;
  font-weight: bold;
`;

export const StyledDatePicker = styled(DatePicker)`
 width: 100%; 
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;
