import styled from 'styled-components';
import colors from '../../utils/colors';
import { NunitoBold12 } from '../../utils/fonts';
import { Column, Row } from '../../utils/layout';

export const HostAdminPageContainer = styled(Row)`
  width: 100%;
  height: 100%;
`;

export const SideBarContainer = styled(Column)`
  width: 10%;
  height: 100%;
`;

export const ContentContainer = styled(Column)`
  width: 90%;
  height: 100%;
  max-width: 90%;
  max-height: 100%;
  padding: 1rem;
`;

export const VioraAdminHeader = styled(NunitoBold12)`
  color: ${colors.black};
`;
