import React, { FC } from 'react';
import { CustomButton } from '../../components/utils/table.styled';
import {
  ConfirmationDialogContainer,
  ConfirmationTitle,
  ConfirmationDescription,
  ConfirmationButtons,
} from './confirmationDialog.style';
import { useAppDispatch } from '../../redux/hooks';
import { hideModal } from '../../redux/modal/modalSlice';
import colors from '../colors';

type Props = {
  onConfirm: () => void;
  onCancel?: () => void;
  title?: string;
  description?: string;
};

const ConfirmationDialog: FC<Props> = ({ onConfirm, onCancel, title, description }) => {
  const dispatch = useAppDispatch();

  const handleCancelPressed = () => {
    dispatch(hideModal());
    onCancel && onCancel();
  };

  return (
    <ConfirmationDialogContainer>
      <ConfirmationTitle>{title || <>Are you sure?</>}</ConfirmationTitle>
      <ConfirmationDescription>{description || ''}</ConfirmationDescription>
      <ConfirmationButtons>
        <CustomButton color={colors.info} onClick={handleCancelPressed}>
          Cancel
        </CustomButton>
        <CustomButton color={colors.danger} onClick={onConfirm}>
          Confirm
        </CustomButton>
      </ConfirmationButtons>
    </ConfirmationDialogContainer>
  );
};

export default ConfirmationDialog;
