import styled from 'styled-components';

const CenteredText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const Roboto = styled(CenteredText)`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
`;

export const Roboto12 = styled(Roboto)`
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
`;

export const RobotoBold12 = styled(Roboto)`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Nunito = styled(CenteredText)`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
`;

export const Nunito12 = styled(Nunito)`
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
`;

export const NunitoBold12 = styled(Nunito)`
  font-size: 1.2rem;
  font-weight: bold;
`;
