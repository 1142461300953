import styled from 'styled-components';
import colors from '../../utils/colors';

export const LandingScreenContainer = styled.div`
  padding: 5px 10px;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LandingScreenTitle = styled.h1`
  margin-top: 50px;
  margin-bottom: 30px;
  color: ${colors.text};
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
`;

export const LandingPageSection = styled.div`
  width: 100%;
  margin-bottom: 30px;
  background-color: ${colors.background_shaded};
  align-items: flex-start;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`;

export const LandingPageHeading = styled.h2`
  text-align: left;
  margin: 5px 15px;
  color: ${colors.text};
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
`;

export const LandingPageImage = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

export const LandingPageDescription = styled.div`
  text-align: left;
  margin: 5px 10px;
  color: ${colors.text};
  font-family: 'Poppins', sans-serif;
  font-size: 1em;

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;
