import Button from 'react-bootstrap/esm/Button';
import styled from 'styled-components';
import colors from '../../utils/colors';

export const CustomButton = styled(Button)<{ color?: string }>`
  white-space: nowrap;
  width: auto;
  margin: 0.5rem 1rem 1rem 1rem;
  background-color: ${props => props.color};
  font-weight: bold;
  border-radius: 5px;
  border: 0;
`;

export const Actions = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HorizontalDivider = styled.div`
  width: 90%;
  height: 1rem;
  align-self: center;
  border-bottom: 1px solid ${colors.black};
`;

export const VerticalDivider = styled.div<{ color?: string }>`
  width: 1rem;
  height: 100%;
  align-self: center;
  border-right: 1px solid ${props => props.color || colors.black};
`;
