/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import storage from '../utils/storage';

export const AUTHORIZATION_HEADER_NAME = 'Authorization';

const axiosInstance: AxiosInstance = axios.create({
  // baseURL: `${"https://localhost:7290/api/"}`,
  baseURL: `${'https://myhostapi-prod.azurewebsites.net/api/'}`,
});

axiosInstance.defaults.headers.common[AUTHORIZATION_HEADER_NAME] = storage.getToken()
  ? `Bearer ${storage.getToken()}`
  : '';

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  err => {
    return Promise.reject(err);
  },
);

export const setAccessToken = (token: string) => {
  axiosInstance.defaults.headers.common[AUTHORIZATION_HEADER_NAME] = `Bearer ${token}`;
};

export const removeAccessToken = () => {
  delete axiosInstance.defaults.headers.common[AUTHORIZATION_HEADER_NAME];
};

export default axiosInstance;
