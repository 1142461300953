import { PremiseAnalyticsRequestModel, PremiseAnalyticsResponseModel } from '../../models/analytics/premiseAnalytics';
import { ReservationAnalyticsResponseModel } from '../../models/analytics/reservationAnalytics';
import { GiveawayResponseModel, GiveawayRequestModel } from '../../models/giveaway/giveaway';
import axiosInstance from '../networkAxios';

export const getPremisesAnalytics = async (request: PremiseAnalyticsRequestModel): Promise<PremiseAnalyticsResponseModel[]> => {
  try {
    const response = await axiosInstance.post('/analytics/premise', request);
    return response.data;
  } catch (error) {
    console.error('Error fetching premises analytics:', error);
    throw error;
  }
};

export const getReservationAnalytics = async (premiseId: string): Promise<ReservationAnalyticsResponseModel[]> => {
  try {
    const response = await axiosInstance.get(`/analytics/reservation/${premiseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation analytics:', error);
    throw error;
  }
};

export const getGiveawayData = async (request: GiveawayRequestModel): Promise<GiveawayResponseModel[]> => {
  try {
    const response = await axiosInstance.get('/analytics/giveaway', {
      params: {
        fromDate: request.fromDate ? request.fromDate.toISOString() : null,
        toDate: request.toDate ? request.toDate.toISOString() : null,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching giveaway data:', error);
    throw error;
  }
};