import styled from 'styled-components';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { Column, Row } from '../../../utils/layout';
import colors from '../../../utils/colors';

export const TableFormContainer = styled(Column)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.transparentBackground};
`;

export const TableFormWrapper = styled(Column)`
  align-self: center;
  align-content: center;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${colors.white};
  padding: 1rem;
`;
export const FormContainer = styled(Column)`
  padding-inline: 1rem;
`;
export const TableFormButtonsContainer = styled(Row)`
  /* width: 10rem; */
  justify-content: space-between;
  margin: 1rem;
`;

export const NewTableForm = styled(Row)`
  width: 100%;
  padding: 1rem;
  align-items: flex-start;
  justify-content: center;
`;
export const TablePreview = styled(Column)`
  padding: 2rem;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${colors.lightgray};
`;
