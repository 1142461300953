import React, { FC, useEffect, useState } from 'react';
import DataRangeFilter from '../../../components/analytics/DataRangeFilter';
import { GiveawayPageContainer, AnalyticsTitle, AnalyticsContent, AnalyticsTableWrapper, AnalyticsFilters, DatePickerWrapper } from './Giveaway.style';
import { getGiveawayData } from '../../../services/api/analyticsApi'; // Import the API function
import { GiveawayRequestModel } from '../../../models/giveaway/giveaway'; // Import the request model
import GiveawayTable from '../../../components/giveaway/GiveawayTable';

interface GiveawayData {
    userId: string;
    userEmail: string;
    reservationDate: string;
}

const Giveaway: FC = () => {
    const [giveawayData, setGiveawayData] = useState<GiveawayData[]>([]);
    const [filteredData, setFilteredData] = useState<GiveawayData[]>([]);

    useEffect(() => {
        const defaultRequest: GiveawayRequestModel = { fromDate: null, toDate: null };
        fetchGiveawayData(defaultRequest);
    }, []);

    const fetchGiveawayData = async (request: GiveawayRequestModel) => {
        try {
            const data = await getGiveawayData(request);
            setGiveawayData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Error fetching giveaway data:', error);
        }
    };

    const handleDateChange = (fromDate: Date | null, toDate: Date | null) => {
        const request: GiveawayRequestModel = { fromDate, toDate };
        fetchGiveawayData(request);
        const filtered = giveawayData.filter((item) => {
            const reservationDate = new Date(item.reservationDate);
            return (
                (!fromDate || reservationDate >= fromDate) &&
                (!toDate || reservationDate <= toDate)
            );
        });
        setFilteredData(filtered);
    };

    const exportToCSV = () => {
        const csvRows = [];
        csvRows.push(['UserEmail']);

        filteredData.forEach(row => {
            csvRows.push([row.userEmail]);
        });

        const csvString = csvRows.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'giveaway_emails.csv');
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <GiveawayPageContainer>
            <AnalyticsTitle>Giveaway Analytics</AnalyticsTitle>
            <AnalyticsContent>
                <AnalyticsTableWrapper>
                    <GiveawayTable data={filteredData} />
                </AnalyticsTableWrapper>
                <AnalyticsFilters>
                    <DatePickerWrapper>
                        <DataRangeFilter onChange={handleDateChange} />
                    </DatePickerWrapper>
                    <button onClick={exportToCSV}>Export User Emails to CSV</button>
                </AnalyticsFilters>
            </AnalyticsContent>
        </GiveawayPageContainer>
    );
};

export default Giveaway;