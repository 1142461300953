import React, { FC, memo, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';


export const Linking: FC = () => {
    const [url, setUrl] = useState('')
    const { customurl } = useParams();

    // Use an effect to handle the redirection
    useEffect(() => {
        if (customurl) {
            const decodedUrl = decodeURIComponent(customurl);
            window.location.href = decodedUrl;
            setUrl(decodedUrl)
        }
    }, [customurl]);

    const handleUrlPress = () => {
        window.location.href = url;
    }

    return <div>

        <p><p>Redirecting...</p> <Spinner animation='border' /></p>
        <span>Ukoliko vas ne navigira automatski kliknite <u onClick={handleUrlPress} style={{ cursor: 'pointer', color: 'blue' }}> ovde:</u></span>
    </div>
}
